<template>
  <div>
    <v-card class="card-preview">
      <div
        class="pt-8"
        style="
          text-color: #444444;
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;
        "
      >
        {{ $t('userinveracitycomparead') }}
      </div>
      <br />
      <div class="pl-5">
        <v-row>
              <v-col cols="12" md="4" style="margin-top: 23px;">                           
                <v-text-field
                  autocomplete="off"
                  :label="$t('regular')"
                  v-model="search"
                  solo
                  dense                 
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <label>{{ $t('statususer') }}</label>
                <v-autocomplete
                  autocomplete="off"
                  :items="itemsFilter"
                  v-model="StatusUser"
                  solo
                  dense
                  :placeholder="$t('select')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>{{ $t('statuscertificate') }}</label>
                <v-autocomplete
                  autocomplete="off"
                  solo
                  v-model="StatusCert"
                  :items="itemsFilter"
                  dense
                  :placeholder="$t('select')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" style="margin-top: 23px;">
                <v-btn
                  color="#126496"
                  class="mr-2 text-capitalize"
                  style="color: white; float: left;"
                  width="120"
                  @click="ApplyFilter()"
                  >{{ $t('applyfilter') }}</v-btn
                >
              </v-col>
            </v-row>
        <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          :headers="headers"
          :items="ListData"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-header
          hide-default-footer
          :no-data-text="$t('nodataavailable')"
        >
          <template v-slot:header>
            <thead>
              <tr>
                <th
                  style="min-width: 180px !important;background-color: #126496"
                  class="b-text"
                >
                  {{ $t(headers[0].text) }}
                </th>
                <th
                  style="min-width: 180px !important;background-color: #126496"
                  class="b-text"
                >
                  {{ $t(headers[1].text) }}
                </th>
                <th
                  class="b-text"
                  style="min-width: 180px !important;background-color: #126496"
                >
                  {{ $t(headers[2].text) }}
                </th>

                <th
                  style="min-width: 180px !important;background-color: #126496"
                  class="b-text"
                >
                  {{ $t(headers[3].text) }}
                </th>
                <th
                  style="min-width: 180px !important;background-color: #126496"
                  class="b-text"
                >
                  {{ $t(headers[4].text) }}
                </th>
                <th
                  style="min-width: 180px !important;text-align: center;background-color: #126496"
                  class="b-text"
                >
                  {{ $t(headers[5].text) }}
                </th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item, index }">
            <tr>
              <td>{{ no_run + (index + 1) }}</td>
              <td>{{ item.samAccountName }}</td>
              <td>{{ item.givenName }}</td>
              <td>{{ item.sn }}</td>
              <td class="pl-1">
                <v-btn
                  rounded
                  width="100"
                  text
                  :style="{
                    color: item.statusUser == true ? '#22A534' : ' #FF6565',
                    background: item.statusUser == true ? '#C6E8CB' : '#FFD8D8',
                  }"
                  >{{ item.statusUser == true ? $t('yes') : $t('no') }}</v-btn
                >
              </td>
              <td class="pl-1" style="text-align: center">
                <v-btn
                  rounded
                  width="100"
                  text
                  :style="{
                    color: item.isCertificate == true ? '#22A534' : ' #FF6565',
                    background: item.isCertificate == true ? '#C6E8CB' : '#FFD8D8',
                  }"
                  >{{ item.isCertificate == true ? $t('yes') : $t('no') }}</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-spacer></v-spacer>

        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="8" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="ChangePerPage(itemsPerPage)"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                @input="ChangePage(page)"
                color="#FFCD2C"
              ></v-pagination>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data() {
    return {
      flagAlertSend: false,
      flagLoadingSend:false,
      mesAlertSend: "",
      typeAlertSend: "success",
      ListData: [],
      itemsFilter: [],
      StatusUser: '',
      StatusCert: '',
      no_run: null,

      search: "",
      deletesearch: "",
      v_CompanyName: false,
      v_CompanyCode: false,
      v_Phone: false,
      v_Email: false,
      v_Country: false,
      v_City: false,
      v_Address: false,
      v_UserID: false,
      v_Password: false,
      //userid: store.state.username,
      loadingdialog: false,
      detaildialog: false,
      adddialog: false,
      editdialog: false,
      status: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      activeFlag: null,
      company_ID: 0,
      items: [10, 20, 30],
      url: enurl.apiUrl,

      //add
      AddcompanyName: "",
      AddcompanyCode: "",
      AddtelephoneNo: "",
      checkphone: false,
      checkemail: false,
      Addemail: "",
      Addcountry: "",
      Addcity: "",
      Addaddress: "",
      AdduserID: "",
      Addpassword: "",
      Addstatus: false,

      //Detail
      DetailcompanyName: null,
      DetailcompanyCode: null,
      DetailtelephoneNo: null,
      Detailemail: null,
      Detailcountry: null,
      Detailcity: null,
      Detailaddress: null,
      DetailuserID: 0,
      Detailpassword: null,
      Detailstatus: false,

      //Update
      UpdatecompanyName: "",
      UpdatecompanyCode: "",
      UpdatetelephoneNo: "",
      Updateemail: "",
      Updatecountry: "",
      Updatecity: "",
      Updateaddress: "",
      UpdateuserID: "",
      Updatepassword: "",
      Updatestatus: false,

      //delete
      companyID: 0,

      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //Inactive Company
      page1: 1,
      pageCount1: 0,
      itemsPerPage1: 10,

      CompanyList: [],
      Deletecompanylist: [],
      email: "",

    };
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "adaccount",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "firstname",
          value: "companyName",
          align: "left",
          sortable: true,
        },
        {
          text: "lastname",
          value: "email",
          align: "left",
          sortable: true,
        },
        {
          text: "statususer",
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: "statuscertificate",
          align: "left",
          value: "status",
          sortable: true,
        },
        // {
        //   text: "Active",
        //   value: "actions",
        //   sortable: true,
        //   align: "left",
        // },
      ];
    },
    removeheaders() {
      return [
        {
          text: "Company Code",
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: "Company Name",
          value: "companyName",
          align: "left",
          sortable: true,
        },

        {
          text: "Email",
          align: "left",
          value: "email",
          sortable: true,
        },
        {
          text: "Telephone Number",
          align: "left",
          value: "telephoneNo",
          sortable: true,
        },
        {
          text: "Status",
          align: "left",

          sortable: true,
        },
        {
          text: "Active",
          value: "removeactions",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateItemsFilter();
      },
      immediate: true,
    },
  },
  mounted() {
    this.GetDataUserAD();
  },

  methods: { 
    updateItemsFilter() {
      this.itemsFilter = [
        { text: this.$t('all'), value: 'All' },
        { text: this.$t('yes'), value: 'Yes' },
        { text: this.$t('no'), value: 'No' }
      ];
      this.StatusUser = this.$t('all');
      this.StatusCert = this.$t('all');
    },
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    ChangePage(val){
      let self = this;   
      let tempdata = {
        Search: self.search,
        StatusUser: self.StatusUser == this.$t('yes') ? true : self.StatusUser == this.$t('no') ? false : null,
        StatusCert: self.StatusCert == this.$t('yes') ? true : self.StatusCert == this.$t('no') ? false : null,
        Page: val,
        Perpage: self.itemsPerPage
      };
      self.loadingdialog = true;
      axios
        .post(`${self.url}SyncManagement/GetDataADForCompareAD`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.no_run = ((self.page - 1) * self.itemsPerPage);
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = self.$t('successfully');
            self.flagAlertSend = true;
            self.ListData = [];
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.loadingdialog = false;
        });
    },

    ChangePerPage(val){
      let self = this;   
      let tempdata = {
        Search: self.search,
        StatusUser: self.StatusUser == this.$t('yes') ? true : self.StatusUser == this.$t('no') ? false : null,
        StatusCert: self.StatusCert == this.$t('yes') ? true : self.StatusCert == this.$t('no') ? false : null,
        Page: 1,
        Perpage: val
      };
      self.loadingdialog = true;
      axios
        .post(`${self.url}SyncManagement/GetDataADForCompareAD`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = self.$t('successfully');
            self.flagAlertSend = true;
            self.ListData = [];
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.page = 1;
          self.pageCount = 0;
          self.loadingdialog = false;
        });
    },

    GetDataUserAD: function () {
      let self = this;
      let tempdata = {
        Page: self.page,
        Perpage: self.itemsPerPage
      };
      self.loadingdialog = true;
      axios
        .post(`${self.url}SyncManagement/GetDataADForCompareAD`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = self.$t('successfully');
            self.flagAlertSend = true;
            self.ListData = [];
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.page = 1;
          self.pageCount = 0;
          self.loadingdialog = false;
        });
    },
    ApplyFilter(){
      let self = this;   
      let tempdata = {
        Search: self.search,
        StatusUser: self.StatusUser == this.$t('yes') ? true : self.StatusUser == this.$t('no') ? false : null,
        StatusCert: self.StatusCert == this.$t('yes') ? true : self.StatusCert == this.$t('no') ? false : null,
        Page: 1,
        Perpage: self.itemsPerPage
      };
      self.loadingdialog = true;
      axios
        .post(`${self.url}SyncManagement/GetDataADForCompareAD`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.page = 1;
            self.loadingdialog = false;
          } else {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = self.$t('successfully');
            self.flagAlertSend = true;
            self.ListData = [];
            self.page = 1;
            self.pageCount = 0;
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.ListData = [];
          self.loadingdialog = false;
        });
    }
  },
};
</script>
<style scoped>
.card-preview {
  border-radius: 5px !important;
  height: 100%;
  padding: 0;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.text-capitalize {
  font-size: 14px;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.delbtn {
  background-color: #1976d2;
  border-radius: 20px;
}
* >>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #1976d2 !important;
}
* >>> .theme--light.v-data-table thead tr th {
  /* color: #1976d2 !important; */
  color: white !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
* >>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
*
  >>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

*
  >>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  /* background: #1976d2 !important; */
  color: white !important;
}
* >>> .v-data-table tr td {
  height: 70px;
}
* >>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #1976d2;
  border-radius: 3px;
  height: 42px;
}
* >>> .mdi-paperclip::before {
  content: none;
}
* >>> .foricon .mdi:before {
  color: #1976d2;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #1976d2 !important;
}
.title {
  font-size: 20px;
}
</style>
