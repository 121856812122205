<template>
  <div>
    <v-card>
      <div class="container">
        <div class="title_header">{{ $t('mailboardcast') }}</div>

        <div class="subject_header">{{ $t('subject') }}</div>
        <div class="subject_input_container">
          <v-text-field
            class="subject_input"
            v-model="subject"
            solo
            required
          ></v-text-field>
        </div>

        <div class="message_header">{{ $t('message') }}</div>
        <div class="message_editor_container" ref="targetDiv">
          <VueEditor v-model="message" :key="message_key" />
        </div>

        <div class="sendEmail_btn_container">
          <v-btn
            color="#126496"
            class="sendEmail_btn white--text"
            @click="SendEmail()"
            >{{ $t('sendemail') }}</v-btn
          >
        </div>
      </div>
    </v-card>

    <Loading :flagLoading="flagLoadingSend" />

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import enurl from "@/api/environment";
import Loading from "@/components/Loading";
import Alert from "@/components/Alert";
export default {
  components: { VueEditor, Loading, Alert },
  data: () => ({
    subject: "",
    message: "",
    message_key: 1,
    url: enurl.apiUrl,
    flagLoadingSend: false,
    flagAlertSend: false,
    typeAlertSend: "success",
    mesAlertSend: "",
  }),
  methods: {
    async SendEmail() {
      if (this.validationOnSendEmail()) {
        this.flagLoadingSend = true;
        let temp = {
          subject: this.subject,
          message: this.FixCheckboxToWorkInMail(
            this.FixBlockquoteToWorkInMail(this.message)
          ),
        };
        axios
          .post(`${this.url}MailBoardcast/SendMailBoardcast`, temp)
          .then(() => {
            this.flagLoadingSend = false;
            this.flagAlertSend = true;
            this.typeAlertSend = "success";
            this.mesAlertSend = this.$t('youremailhasbeensuccessfullysent');

            const qlEditor = document.querySelector(".ql-editor");
            if (qlEditor) {
              qlEditor.innerHTML = "";
            }
            this.subject = "";
            this.message = "";
          });
      }
    },
    validationOnSendEmail() {
      if (!this.subject || !this.message) {
        this.flagLoadingSend = false;
        this.flagAlertSend = true;
        this.typeAlertSend = "warning";
        this.mesAlertSend = this.$t('pleasefillsubjectandmessage');
        return false;
      }
      return true;
    },

    convertClassesToInlineStyles(message) {
      const div = document.createElement("div");
      div.innerHTML = message;

      const elementsWithClasses = div.querySelectorAll("[class]");
      elementsWithClasses.forEach((element) => {
        const classes = element.classList;

        for (const className of classes) {
          const style = this.getStyleForClass(className);
          element.style.cssText += style;
        }

        element.removeAttribute("class");
      });

      this.message = div.innerHTML;
    },
    getStyleForClass(className) {
      switch (className) {
        case "ql-align-center":
          return "text-align: center;";
        case "ql-align-right":
          return "text-align: right;";
        case "ql-syntax":
          return "background-color: #23241f; color: #f8f8f2; overflow: visible; white-space: pre-wrap; margin-bottom: 5px; margin-top: 5px; padding: 5px 10px;";
        case "ql-indent-1":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-2":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-3":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-4":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-5":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-6":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-7":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        case "ql-indent-8":
          return `padding-left: ${parseInt(className.match(/\d+$/)[0]) * 3}em;`;
        // Add more cases as needed
        default:
          return "";
      }
    },

    FixBlockquoteToWorkInMail(message) {
      // Replace opening <blockquote> tag
      let resultString = message.replace(
        /<blockquote>/g,
        '<div style="border-left: 4px solid #CCCCCC; padding-left: 16px;">'
      );
      // Replace closing </blockquote> tag
      resultString = resultString.replace(/<\/blockquote>/g, "</div>");

      return resultString;
    },
    FixCheckboxToWorkInMail(input) {
      // Create a dummy div element to parse the input HTML string
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = input;

      // Find all ul elements with data-checked attribute
      let ulElements = tempDiv.querySelectorAll("ul[data-checked]");

      // Iterate through each ul element and modify its content
      ulElements.forEach(function (ulElement) {
        // Get the value of the data-checked attribute
        let isChecked = ulElement.getAttribute("data-checked") === "true";

        // Iterate through each li element and modify its content
        let liElements = ulElement.querySelectorAll("li");
        liElements.forEach(function (liElement) {
          // Create a new p element
          let newP = document.createElement("p");

          // Add a checkbox (☐ or ☑) based on the value of isChecked
          newP.innerHTML = isChecked ? "☑ " : "☐ ";

          // Append the content of the original li element to the new p element
          newP.innerHTML += liElement.innerHTML;

          // Replace the original li element with the new p element
          liElement.replaceWith(newP);
        });
      });

      // Return the modified HTML content
      return tempDiv.innerHTML;
    },

    ClearDataAlert() {
      this.flagAlertSend = false;
      self.typeAlertSend = ""; 
      this.mesAlertSend = "";
    },
  },
  watch: {
    message(v) {
      this.convertClassesToInlineStyles(v);
      // this.convertClassToInlineStyle();
    },
  },
};
</script>

<style>
.container {
  padding: 1rem;
}
.title_header {
  color: #444444;
  font-size: 24px;
}
.subject_header,
.message_header {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #515357;
  font-size: 18px;
}
.subject_input_container {
  padding-left: 18px;
}
.message_editor_container {
  padding-left: 18px;
}
.message_editor_container .ql-video {
  display: none !important;
}
.sendEmail_btn_container {
  margin-top: 30px;
  display: flex;
  justify-content: right;
}
</style>
