import { render, staticRenderFns } from "./UserManual.vue?vue&type=template&id=7988f23a&scoped=true"
import script from "./UserManual.vue?vue&type=script&lang=js"
export * from "./UserManual.vue?vue&type=script&lang=js"
import style0 from "./UserManual.vue?vue&type=style&index=0&id=7988f23a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7988f23a",
  null
  
)

export default component.exports