<template>
  <div>
    <v-card class="card-preview">
      <div
        class="pt-8"
        style="
          text-color: #444444;
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;
        "
      >
        {{ $t('configscheduler') }}
      </div>

      <div class="pl-5">
        <v-row>
          <v-col cols="12" md="12">
            <div style="text-align: center">
              <v-row>
                <v-col cols="12" md="3">
                  <v-radio-group row v-model="ScheduleType">
                    <v-radio :label="$t('daily')" value="1"></v-radio>
                    <v-radio :label="$t('weekly')" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="2">
                  <!-- <v-text-field
                      autocomplete="off"
                      v-model="ShowTimeText"
                      disabled
                      solo
                      dense
                    ></v-text-field> -->
                </v-col>
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="3">
                  
                </v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <div v-if="ScheduleType == '2'">
                    <v-checkbox
                      :label="$t('sunday')"
                      color="#126496"
                      hide-details
                      v-model="SundayCheckbox"
                    ></v-checkbox>
                    <v-checkbox
                      :label="$t('monday')"
                      color="#126496"
                      hide-details
                      v-model="MondayCheckbox"
                    ></v-checkbox>
                    <v-checkbox
                      :label="$t('tuesday')"
                      color="#126496"
                      hide-details
                      v-model="TuesdayCheckbox"
                    ></v-checkbox>
                    <v-checkbox
                      :label="$t('wednesday')"
                      color="#126496"
                      hide-details
                      v-model="WednesdayCheckbox"
                    ></v-checkbox>
                    <v-checkbox
                      :label="$t('thursday')"
                      color="#126496"
                      hide-details
                      v-model="ThursdayCheckbox"
                    ></v-checkbox>
                    <v-checkbox
                      :label="$t('friday')"
                      color="#126496"
                      hide-details
                      v-model="FridayCheckbox"
                    ></v-checkbox>
                    <v-checkbox
                      :label="$t('saturday')"
                      color="#126496"
                      hide-details
                      v-model="SaturdayCheckbox"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-time-picker
                    format="24hr"
                    color="green lighten-1"
                    v-model="Timepicker"
                    scrollable
                  ></v-time-picker>
                </v-col>
                <v-col cols="12" md="3" style="margin-top: -7%;"> 
                  {{ $t('latestsettime') }} : {{ Timepicker }}<br>
                    <!-- Set Time Day: <br>
                   <span v-if="SundayCheckbox == true">{{ "Sunday" }}</span> <br> 
                   <span v-if="MondayCheckbox == true">{{ "Monday" }}</span><br>
                   <span v-if="TuesdayCheckbox == true">{{ "Tuesday" }}</span><br>
                   <span v-if="WednesdayCheckbox == true">{{ "Wednesday" }}</span><br>
                   <span v-if="ThursdayCheckbox == true">{{ "Thursday" }}</span><br>
                   <span v-if="FridayCheckbox == true">{{ "Friday" }}</span><br>
                   <span v-if="SaturdayCheckbox == true">{{ "Saturday" }}</span><br> -->
                </v-col>
              </v-row>
            </div>
            <br />
            <div style="text-align: center">
              <v-row>
                <v-col cols="12" md="4"> </v-col>
                <v-col cols="12" md="4"> 
                  <v-btn
                    color="#126496"
                    class="mr-5 white--text text-capitalize"
                    width="120"
                    @click="SetTimeDialog = true"
                    >{{ $t('set') }}</v-btn
                  >
                </v-col>
                <v-col cols="12" md="4"> </v-col>
                <!-- <v-col cols="12" md="2">
                  <v-btn
                    color="#E5E7E9"
                    class="mr-5 text-capitalize"
                    width="120"
                    >Cancel</v-btn
                  >
                </v-col> -->
              </v-row>
            </div>
            <br />
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="SetTimeDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span
            ><v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/FontAwsome (clock)@2x.png"
            ></v-img
          ></span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            {{ $t('confirmtosettime') }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t('doyouwanttosetautosynctimeto') }} <span style="color: #126496">{{ Timepicker }}</span> ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="SetTimeDialog = false"
            text
            >{{ $t('cancel') }}</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="SetTime()"
            >{{ $t('confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import moment from 'moment'

export default {
  data() {
    return {
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      ScheduleType: "1",
      Timepicker: "",
      SundayCheckbox: "",
      MondayCheckbox: "",
      TuesdayCheckbox: "",
      WednesdayCheckbox: "",
      ThursdayCheckbox: "",
      FridayCheckbox: "",
      SaturdayCheckbox: "",
      ShowTimeText: "",
      SetTimeDialog: false,

      search: "",
      deletesearch: "",
      v_CompanyName: false,
      v_CompanyCode: false,
      v_Phone: false,
      v_Email: false,
      v_Country: false,
      v_City: false,
      v_Address: false,
      v_UserID: false,
      v_Password: false,
      //userid: store.state.username,
      loadingdialog: false,
      detaildialog: false,
      adddialog: false,
      editdialog: false,
      status: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      activeFlag: null,
      company_ID: 0,
      items: [10, 20, 30],
      url: enurl.apiUrl,

      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //Inactive Company
      page1: 1,
      pageCount1: 0,
      itemsPerPage1: 10,

      CompanyList: [],
      Deletecompanylist: [],
      email: "",
      name:'Alert'
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('companycode'),
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: this.$t('companyname'),
          value: "companyName",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t('email'),
          value: "email",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t('telephonenumber'),
          align: "left",
          value: "telephoneNo",
          sortable: true,
        },

        {
          text: this.$t('status'),
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: this.$t('active'),
          value: "actions",
          sortable: true,
          align: "left",
        },
      ];
    },
    removeheaders() {
      return [
        {
          text: this.$t('no'),
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: this.$t('adaccount'),
          value: "companyName",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t('firstname'),
          align: "left",
          value: "email",
          sortable: true,
        },
        {
          text: this.$t('lastname'),
          align: "left",
          value: "telephoneNo",
          sortable: true,
        },
        {
          text: this.$t('action'),
          value: "removeactions",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  watch: {},
  mounted() {

  },

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    SetTime() {
      var self = this;
      // self.ShowTimeText = self.Timepicker;
      let strTime = String(new Date('1970-01-01T'+self.Timepicker))
      var utcTime = moment(strTime).utc();
      var strUTCTime = utcTime.format('HH:mm');
      let tempdata = {
        scheduleType: self.ScheduleType == "1" ? 1 : 2,
        time: strUTCTime,
        syncAll: false,
        sunday: self.SundayCheckbox == "" ? false : self.SundayCheckbox,
        monday: self.MondayCheckbox == "" ? false : self.MondayCheckbox,
        tuesday: self.TuesdayCheckbox == "" ? false : self.TuesdayCheckbox,
        wednesday:
          self.WednesdayCheckbox == "" ? false : self.WednesdayCheckbox,
        thursday: self.ThursdayCheckbox == "" ? false : self.ThursdayCheckbox,
        friday: self.FridayCheckbox == "" ? false : self.FridayCheckbox,
        saturday: self.SaturdayCheckbox == "" ? false : self.SaturdayCheckbox,
      };
      axios
        .post(`${self.url}SyncManagement/SetDataConfigurationSchedule`, tempdata)
        // .get(`${self.url}Cert/GetCertAll`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = self.$t('addsuccess');
            self.flagAlertSend = true;
            window.location.reload();
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },
};
</script>
<style scoped>
.card-preview {
  border-radius: 5px !important;
  height: 100%;
  /* overflow: auto; */
  padding: 0;
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.text-capitalize {
  font-size: 14px;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.delbtn {
  background-color: #1976d2;
  border-radius: 20px;
}
* >>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #1976d2 !important;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #1976d2 !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
* >>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
*
  >>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

*
  >>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #1976d2 !important;
  color: white !important;
}
* >>> .v-data-table tr td {
  height: 70px;
}
* >>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #1976d2;
  border-radius: 3px;
  height: 42px;
}
* >>> .mdi-paperclip::before {
  content: none;
}
* >>> .foricon .mdi:before {
  color: #1976d2;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #1976d2 !important;
}
.title {
  font-size: 20px;
}
</style>
