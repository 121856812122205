<template>
    <div>
      <v-container fluid>
        <v-flex class="flex">
          
          <div class="text-center">
            <v-row justify="end" class="ml-5 mr-5">
              <v-spacer />
              <v-col cols="12" md="4">
                <div>
                  <v-btn
                    @click="onPickPreviewFile"
                    color="primary"
                    class="white--text mt-2 text-capitalize"
                    router
                    width="225"
                    >{{ $t('upload') }}</v-btn
                  >
                  <input
                    ref="uploadPreviewFile"
                    id="preview-file-upload"
                    name="preview-file-input"
                    type="file"
                    style="display: none"
                    @change="handlePreviewFileInput"
                    accept="application/pdf"
                  />
                </div>
              </v-col>
              <v-spacer />
            </v-row>
            <br />
          </div>
  
          <div>
            <div class="d-flex justify-center">
              <v-btn
                color="#03C7F9"
                class="white--text mt-2"
                router
                width="225"
                @click="showPreview()"
                >{{ $t('preview') }}</v-btn
              >
            </div>
          </div>

   
          <div class="text-center mt-5" v-show="PreviewStatus">
            <div id="pdf"></div>
          </div>
  
          <div class="d-flex justify-end">
            <!-- <v-btn color="#B8B8B8" class="mt-10" style="color: white; font-size: 12px;" @click="GotoCompanyManagement()">Cancel</v-btn> -->
            <v-btn color="#1976D2" class="mt-10" style="color: white;" @click="SaveDialog = true" :disabled="IsSaveDisable()">{{ $t('save') }}</v-btn>
          </div>
        </v-flex>
      </v-container>
      
      <br>
      <br>
      <br>
      <br>
      <Footer />


      <v-dialog v-model="SaveDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>
          </span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
            {{ $t('saveusermanual') }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t('doyouwanttosaveusermanual') }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="SaveDialog = false"
            text
            >{{ $t('cancel') }}</v-btn
          >
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="SaveUserManual()"
            >{{ $t('save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  
      <Loading
        :flagLoading="flagLoadingSend"
      />
  
      <Alert
        :flagAlert="flagAlertSend"
        :typeAlert="typeAlertSend"
        :mesAlert="mesAlertSend"
        @flagClose="ClearDataAlert()"
      />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import enurl from "@/api/environment";
  import Footer from "./../components/Footer.vue";
  // import store from "../store";
  import Alert from '@/components/Alert';
  import Loading from "@/components/Loading";
  
  export default {
    components:{
      Footer,
      Loading,
      Alert,
    },
    props: {
    },
    data: () => ({
      PreviewStatus: false,
      url: enurl.apiUrl,
      flagLoadingSend: false,
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      previewFiles: [],
      files: [],
      previewFileName: "",
      fileName: "",
      SaveDialog: false,
      base64String: null,
      pdfElement: null
    }),
    watch: { 
    },
    mounted() {

    },
    methods: {
      IsSaveDisable(){
        if(this.previewFileName){
          return false
        }else{
          return true
        }
      },

      ClearDataAlert() {
        let self = this;
        self.flagAlertSend = false;
        self.mesAlertSend = "";
        // location.reload();
      },

      onPickPreviewFile(){ 
        this.$refs.uploadPreviewFile.click();
      },

      showPreview() {
        let self = this;
        self.PreviewStatus = !self.PreviewStatus;
        if (self.base64String == null && !self.pdfElement) {
          self.flagLoadingSend = true;
          axios
            .get(`${self.url}Documents/GetUserManualPdf`)
            .then((response) => {
              self.flagLoadingSend = false;
              self.base64String = response.data.data.base64String;
              const blobPDF = this.base64ToBlob(self.base64String, "application/pdf");
              const linkSource = URL.createObjectURL(blobPDF);
              const obj = document.createElement("embed");
              obj.style.width = "100%";
              obj.style.height = "550px";
              obj.type = "application/pdf";
              obj.src = linkSource;

              const pdfUserManualElement = document.getElementById("pdf");
              while (pdfUserManualElement.firstChild) {
                pdfUserManualElement.removeChild(pdfUserManualElement.firstChild);
              }
              pdfUserManualElement.appendChild(obj);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },


      base64ToBlob(base64, type = "application/octet-stream") {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        // Convert base64 string to Uint8Array
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        // Create and return a Blob from the Uint8Array
        return new Blob([arr], { type: type });
      },


      async handlePreviewFileInput(e) {
        let self = this;
        self.flagLoadingSend = true;
        self.previewFiles = [];
        let files = e.target.files;
        
        if(files.length > 0)
        {
          const validateFileType = () => {
            const allowedTypes = [
              "application/pdf"
            ];
            return allowedTypes.includes(files[0].type);
          };
          

          const validateFileSize = () => {
            const maxSize = 20480000; // 20MB
            return files[0].size <= maxSize;
          };

          if (!validateFileType()) {
            self.typeAlertSend = "warning";
            self.mesAlertSend = self.$t('pleaseattachapdffileonly');
            self.flagAlertSend = true;
          } else if (!validateFileSize()) {
            self.typeAlertSend = "warning";
            self.mesAlertSend = self.$t('pleaseuploadafilenolargerthan20mb');
            self.flagAlertSend = true;
          } else {
            self.previewFileName = files[0].name;
            self.previewFiles = files[0];
            self.SetDataPreviewPDF();
          }
          
          self.pdfElement = document.getElementById("pdf"); 
          while (self.pdfElement.firstChild) {
            self.pdfElement.removeChild(self.pdfElement.firstChild);
          }
        }
        self.flagLoadingSend = false;
      },

      async fileToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64 = reader.result.split(',')[1];
            resolve(base64);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      },
  
      
    async SetDataPreviewPDF() {
      let self = this;
      self.pdfString = await self.fileToBase64(self.previewFiles);

      const blobPDF = self.base64ToBlob(self.pdfString, "application/pdf");
      const linkSource = URL.createObjectURL(blobPDF);

      const obj = document.createElement("embed");
      obj.style.width = "100%";
      obj.style.height = "550px";
      obj.type = "application/pdf";
      obj.src = linkSource;
      document.getElementById("pdf").appendChild(obj);
    },

      async SaveUserManual() {
        let self = this;
        self.flagLoadingSend = true;

        if (self.pdfString != "") {
          let tempdata = {
            PDFString: self.pdfString,
          };
          //console.log(tempdata);
          axios
            .post(`${self.url}Documents/UpdateUserManualPdf`,  tempdata )
            .then(function () {           
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = self.$t('successfully');
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
                self.SaveDialog = false;
              //self.$router.push("/CompanyManagement");
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              alert(error);
            });
        } else {
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = self.$t('pleaseattachfile');
          self.flagAlertSend = true;
        }
      },

      // GotoCompanyManagement(){
      // let self = this;
      // self.$router.push("/CompanyManagement");
      // },
    },
  };
  </script>
  <style scoped>
  :deep(.Header){
    display: none !important;
  }
  :deep(.HeaderToolsContainer){
    display: none !important;
  }
  .card {
    border-radius: 10px !important;
    margin-bottom: 2rem;
    height: 90vh;
    overflow: auto;
    margin: 0;
    padding: 0;
  }
  .flex {
    margin-left: 2rem;
    margin-right: 0rem;
  }
  .circular--portrait {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .circular--portrait img {
    width: 100%;
    height: auto;
  }
  .input-border {
    border: 1px solid #EBEBF2;
    height: 35px;
    padding: 4px;
  }
  .header-title {
    font-size: 13px;
    font-weight: 300;
  }
  ::-webkit-input-placeholder { 
      color: #444444;
      opacity: 20%;
      font-size: 14px;
  }
  .validation-class {
    color: red;
    font-size: 12px;
  }
  </style>