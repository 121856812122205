<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{ $t('historylog') }}</div>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="1">
              <v-autocomplete
                dense
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="GetLogHistoryChangePerPage(itemsPerPage)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="7"></v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-text-field
                dense
                v-model="search"
                v-on:keyup.enter="GetLogHistorySearch(search)"
                outlined
                :placeholder="$t('search')"
                placeholder-color="#ACACAC"
                prepend-inner-icon="mdi-magnify"
                color="#126496"
              />
            </v-col>
          </v-row>
          <div>
            <v-data-table
              :headers="translatedHeaders"
              :items="HistoryLogList"
              hide-default-footer
              style="color: #444444"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              :search-input.sync="search"
              single-line
              hide-details
            >
              <!-- <template v-slot:item.download="{ item }" class="text-end">
                <v-btn
                  text
                  color="#126496"
                  style="text-transform: none !important"
                  @click="downloadURI(item.certFile, item.certImageName)"
                  :disabled="!flag_dowload"
                >Download</v-btn>
              </template> -->
            </v-data-table>
          </div>
          <v-row>
            <v-col cols="12">
              <v-pagination class="__pagination" v-model="page" :total-visible="6" :length="pageCount" @input="GetLogHistoryChangePage(page)"></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },
  data: () => ({
    url: enurl.apiUrl,
    permissionid: store.state.permissionid,
    flag_dowload: false,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [10, 15, 20],
    HistoryLogList: [],
    time: "",
    flagLoadingSend: false,
    headers: [
      {
        text: "Date",
        sortable: false,
        value: "date",
      },
      { 
        text: "Time", 
        sortable: false, 
        value: "time" 
      },
      { 
        text: "Username", 
        sortable: false, 
        value: "username" 
      },
      { 
        text: "CertName", 
        sortable: false, 
        value: "certName" 
      },
      {
        text: "CertReason",
        sortable: false,
        value: "certReason",
      },
      {
        text: "CertLocation",
        sortable: false,
        value: "certLocation",
      },
    ],
  }),
  computed: {
    translatedHeaders() {
      return [
        { text: this.$t('date'), sortable: false, value: 'date' },
        { text: this.$t('time'), sortable: false, value: 'time' },
        { text: this.$t('username'), sortable: false, value: 'username' },
        { text: this.$t('certName'), sortable: false, value: 'certName' },
        { text: this.$t('certReason'), sortable: false, value: 'certReason' },
        { text: this.$t('certLocation'), sortable: false, value: 'certLocation' },
      ];
    },
  },
  methods: {
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    GetLogHistory: function () {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: 1,
        perPage: 10,
        search: self.search == "" ? null : self.search,
      };
      axios.post(`${self.url}Log/GetLogHistoryAll`, temp)
      .then(function (response) {
        if (response.data.status == 0) {
          try 
          {
            // let a = 0;
            // for (let i = 0; i < response.data.data.length; i++) {
            //   try {
            //     response.data.data[i].data != "null" ? response.data.data[i].data.includes("certReason") ? JSON.parse(response.data.data[i].data).certReason : "1" : ""
            //     a = a+1;
            //   }
            //   catch(err) {
            //     console.log(a);
            //     console.log(err);
            //   }
            // }
            self.HistoryLogList = response.data.data.listData.map((v) => ({
              id: v.id,
              date: v.date,
              time: v.time,
              username: v.username,
              certName: v.certName,
              certReason: v.certReason,
              certLocation: v.certLocation,
              // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ? 
              //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") : 
              //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
              // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
              // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
            }));
            self.pageCount = response.data.data.count;
          }
          catch(err) 
          {
            console.log(err);
            self.flagLoadingSend = false;
          }
          self.flagLoadingSend = false;
        } else {
          self.flagLoadingSend = false;
          //alert(response.data.message);
        }
      })
      .catch(error => {
        console.log(error.response);
        self.flagLoadingSend = false;
      });
    },
    GetLogHistoryChangePage(val) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
          page: val,
          perPage: self.itemsPerPage,
          search: self.search == "" ? null : self.search,
        };
      axios.post(`${self.url}Log/GetLogHistoryAll`, temp)
      .then(function (response) {
        if (response.data.status == 0) {
          try 
          {
            self.HistoryLogList = response.data.data.listData.map((v) => ({
              id: v.id,
              date: v.date,
              time: v.time,
              username: v.username,
              certName: v.certName,
              certReason: v.certReason,
              certLocation: v.certLocation,
              // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ? 
              //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") : 
              //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
              // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
              // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
            }));
            self.pageCount = response.data.data.count;
          }
          catch(err) 
          {
            console.log(err);
            self.flagLoadingSend = false;
          }
          self.flagLoadingSend = false;
        } else {
          self.flagLoadingSend = false;
          //alert(response.data.message);
        }
      })
      .catch(error => {
        console.log(error.response);
        self.flagLoadingSend = false;
      });
    },
    GetLogHistoryChangePerPage(val) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: 1,
        perPage: val,
        search: self.search == "" ? null : self.search,
      };
      axios.post(`${self.url}Log/GetLogHistoryAll`, temp)
      .then(function (response) {
        if (response.data.status == 0) {
          try 
          {
            self.HistoryLogList = response.data.data.listData.map((v) => ({
              id: v.id,
              date: v.date,
              time: v.time,
              username: v.username,
              certName: v.certName,
              certReason: v.certReason,
              certLocation: v.certLocation,
              // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ? 
              //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") : 
              //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
              // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
              // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
            }));
            self.pageCount = response.data.data.count;
            self.page = 1;
          }
          catch(err) 
          {
            console.log(err);
            self.flagLoadingSend = false;
          }
          self.flagLoadingSend = false;
        } else {
          self.flagLoadingSend = false;
          //alert(response.data.message);
        }
      })
      .catch(error => {
        console.log(error.response);
        self.flagLoadingSend = false;
      });
    },
    GetLogHistorySearch(val) {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        page: 1,
        perPage: self.itemsPerPage,
        search: val == "" ? null : val,
      };
      axios.post(`${self.url}Log/GetLogHistoryAll`, temp)
      .then(function (response) {
        if (response.data.status == 0) {
          try 
          {
            self.HistoryLogList = response.data.data.listData.map((v) => ({
              id: v.id,
              date: v.date,
              time: v.time,
              username: v.username,
              certName: v.certName,
              certReason: v.certReason,
              certLocation: v.certLocation,
              // certName: v.certID != null ? v.certName : v.data != "null" ? v.data.includes("empCert") ? 
              //   (JSON.parse(v.data).empCert != null ? JSON.parse(v.data).empCert.certName : "") : 
              //   (v.data.includes("certName") ? JSON.parse(v.data).certName : "") : "",
              // certReason: v.certReason != "" && v.certReason != null ? v.certReason : v.data != "null" ? v.data.includes("certReason") ? JSON.parse(v.data).certReason : "" : "",
              // certLocation: v.certLocation != "" && v.certLocation != null ? v.certLocation : v.data != "null" ? v.data.includes("certLocation") ? JSON.parse(v.data).certLocation : "" : "",
            }));
            self.pageCount = response.data.data.count;
            self.page = 1;
          }
          catch(err) 
          {
            console.log(err);
            self.flagLoadingSend = false;
          }
          self.flagLoadingSend = false;
        } else {
          self.flagLoadingSend = false;
          //alert(response.data.message);
        }
      })
      .catch(error => {
        console.log(error.response);
        self.flagLoadingSend = false;
      });
    },
    UTCToLocal(dateStr, format) {
      if (typeof dateStr == "string") {
        let newDateStr = dateStr;
        if (!newDateStr.match(/Z$/)) {
          newDateStr += "Z";
        }
        return moment(newDateStr).format(format);
      }
      return "";
    },
  },
  mounted() {
    this.GetLogHistory();
    const elements = document.querySelectorAll(".v-pagination__navigation");
    // console.log(elements);
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = this.$t('next');
      } else {
        element.textContent = this.$t('previous');
      }
    }
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  *>>> .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
*>>> tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
*>>> .v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
*>>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
*>>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
*>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
*>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>