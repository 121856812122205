<template>
  <div>
    <v-card style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          {{ $t('subscriptionmanagement') }}
          <span class="ml-5 text-caption" style="color: #126496; font-family: English !important;"
            >{{ SubscriptionManagementListCount }} {{ $t('totalrecords') }} </span
          >
          <!-- <span class="ml-5 text-caption" style="color: #126496"
            >{{ ProductPlanCount }} Total records</span -->
        </div>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <div class="d-flex">
                <v-btn
                    style="
                        background-color: #126496;
                        color: #ffffff;
                        text-transform: none !important;
                      "
                    @click="NewSubscription()"
                    class="flex-sm-grow-0 flex-grow-1"
                  >{{ $t('newsubscription') }}</v-btn>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="1">
              <v-menu
                offset-y
                :close-on-content-click="false"
                v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  style="border: 1px solid #EBEBF2"
                    block
                    height="40"
                    class="text-capitalize"
                    outlined
                    color="rgba(0,0,0,0.5)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('filter') }}
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                <v-card style="overflow: auto">
                  <v-card-text style="max-height: 50vh">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">{{ $t('sales') }}</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="6">
                        <v-autocomplete
                              hide-no-data
                              return-object
                              :items="ListContactSale"
                              v-model="Filter.sale"
                              :search-input.sync="InputSearchSales"
                              item-text="fullName"
                              item-value="id"
                              :placeholder="$t('sales')"
                              outlined
                              dense
                              hide-details="auto"
                          ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row class="mt-3">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">{{ $t('status') }}</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <v-checkbox
                          dense
                          hide-details="auto"
                          :label="$t('ACTIVE')"
                          v-model="Filter.workStatus"
                          value="1"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <v-checkbox
                          dense
                          hide-details="auto"
                          :label="$t('INACTIVE')"
                          v-model="Filter.workStatus"
                          value="2"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-row class="mt-3">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">{{ $t('startdate') }}</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.StartDate"
                              readonly
                              v-on="on"
                              outlined
                              :label="$t('ddmmyyy')"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date1"
                            no-title
                            @input="save3"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="1" class="hidden-md-and-down">
                        <p class="text-center mt-2">-</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.EndDate"
                              readonly
                              v-on="on"
                              outlined
                              :label="$t('ddmmyyy')"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date2"
                            no-title
                            @input="save4"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                        <p class="mt-3">{{ $t('expiredate') }}</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menuStartExpiredDate"
                          v-model="menuStartExpiredDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.StartExpiredDate"
                              readonly
                              v-on="on"
                              outlined
                              :label="$t('ddmmyyy')"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="startExpiredDate"
                            no-title
                            @input="saveStartExpiredDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="1" class="hidden-md-and-down">
                        <p class="text-center mt-2">-</p>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                        <v-menu
                          ref="menuEndExpiredDate"
                          v-model="menuEndExpiredDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-model="Filter.EndExpiredDate"
                              readonly
                              v-on="on"
                              outlined
                              :label="$t('ddmmyyy')"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="endExpiredDate"
                            no-title
                            @input="saveEndExpiredDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-3"></v-divider>
                    <div class="d-flex mt-3">
                      <v-btn
                        text
                        class="text-capitalize"
                        color="error"
                        @click="ClearSearchFilter(), date1 = null, date2 = null"
                        >{{ $t('clearallfilters') }}</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        class="text-capitalize"
                        @click="menu = false"
                        >{{ $t('cancel') }}</v-btn
                      >
                      <v-btn
                        text
                        class="text-capitalize"
                        color="primary"
                        @click="FlagSearch = true; SearchFilter()"
                        >{{ $t('apply') }}</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="7" xl="8">
              <v-text-field
                :class="[$vuetify.breakpoint.xlOnly ? 'ml-5' : '']"
                :label="$t('search')"
                v-model="search"
                outlined
                dense
                hide-details="auto"
                v-on:keyup.enter="SearchFilter()"
              >
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-5"
            :headers="headers"
            :items="ProductPlanList"
            hide-default-footer
            style="color: #444444;box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 7%), 0px 1px 1px 1px rgb(0 0 0 / 7%), 0px 1px 1px 0px rgb(0 0 0 / 7%)"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            single-line
            hide-details
            :mobile-breakpoint="0"
          >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>
                        <center><span>{{ item.no + (index + 1) }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.companyName }}</span></center>
                    </td>
                    <!-- <td>
                        <center><span>{{ item.name }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.limitUserUse }}/{{ item.limitSignatureUse }}</span></center>
                    </td> -->
                    <td>
                        <center><span>{{ item.sales }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.startDate }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.expireDate }}</span></center>
                    </td>
                    <td>
                        <center><span>{{ item.reminderDate }}</span></center>
                    </td>
                    <td>
                        <center>
                            <div
                                :style="{'background-color': getColor(item.active)}"
                                style="width: 150px;border-radius: 16px;font-size:14px;height:32px;margin-right:auto;margin-left:auto"
                            >
                                <p style="padding-top:5px" :style="{ color: getColorText(item.active) }">{{
                                item.active == true
                                    ? $t('ACTIVE')
                                    : $t('INACTIVE')
                                }}</p>
                            </div>
                        </center>
                    </td>
                    <td>
                        <center>
                            <v-menu transition="slide-y-transition" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="#126496"
                                    style="text-transform: none !important"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                                </template>
                                <v-list>
                                <v-list-item
                                    v-for="(temp, i) in itemsAction"
                                    :key="i"
                                    @click="SelectAction(temp.title, item)"
                                >
                                    <v-list-item-title>{{ $t(temp.title) }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-menu>
                        </center>
                    </td>
                </tr>
            </template>

          </v-data-table>
          <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  style="border: 1px solid #EBEBF2"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon color="primary">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="
                      itemsPerPage = item.title;
                      ChangePerPageProductPlan(itemsPerPage);
                    "
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-pagination
                
                v-model="page"
                :total-visible="6"
                :length="pageCount"
                @input="ChangePageProductPlan(page)"
              ></v-pagination>
              
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    
    <v-dialog v-model="dialogAlert" persistent max-width="600px">
      <v-card>
        <v-card-title>
        <!-- <span class="headline">{{$t('menumanagement')}}</span> -->
        <span class="headline">

        </span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <div>
            {{textAlert}}
            </div>
        </v-container>
        <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <div>
            <!-- <v-btn class="btn-light" @click="ClosePopupDelete()">Close</v-btn> -->
            <v-btn class="btn-light" @click="dialogAlert = false">{{ $t('close') }}</v-btn>
        </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="flagSubscription" persistent max-width="600">
        <v-card>
          <div
            class="pt-5"
            style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            "
          >{{textSubscription}}</div>
          <v-card-text>
            <!-- <v-form ref="form" v-model="validateModel"> -->
              <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>{{ $t('companyname') }} : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-autocomplete
                                hide-no-data
                                return-object
                                :items="ListCompany"
                                v-model="Company"
                                :search-input.sync="InputSearchCompany"
                                item-text="companyName"
                                item-value="companyID"
                                :placeholder="$t('companyname')"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>{{ $t('startdate') }} : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menuStartDate"
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatStartDate"
                                readonly
                                v-on="on"
                                outlined
                                :label="$t('ddmmyyy')"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="StartDate"
                                no-title
                                @input="save1"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                            <div class="mt-2 txtStyle" style="margin-right: 20px">
                                <p>{{ $t('enddate') }} : </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-menu
                            ref="menuEndDate"
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-bind="attrs"
                                v-model="formatEndDate"
                                readonly
                                v-on="on"
                                outlined
                                :label="$t('ddmmyyy')"
                                append-icon="mdi-calendar-blank"
                                dense
                                hide-details="auto"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="EndDate"
                                no-title
                                @input="save2"
                                :min="StartDate"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row><v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>{{ $t('reminderdate') }} : </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="9">
                        <v-menu
                          ref="menuReminderDate"
                          v-model="menuReminderDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              v-bind="attrs"
                              v-model="formatReminderDate"
                              readonly
                              v-on="on"
                              outlined
                              :label="$t('ddmmyyy')"
                              append-icon="mdi-calendar-blank"
                              dense
                              hide-details="auto"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="ReminderDate"
                              no-title
                              @input="saveReminderDate"
                              :max="EndDate"
                          ></v-date-picker>
                        </v-menu> 
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>{{ $t('package') }} : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                            <v-autocomplete
                                hide-no-data
                                return-object
                                :items="ListPackage"
                                v-model="Package"
                                :search-input.sync="InputSearchPackage"
                                item-text="name"
                                item-value="id"
                                :placeholder="$t('package')"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>{{ $t('sales') }} : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                          <v-autocomplete
                              hide-no-data
                              return-object
                              :items="ListContactSale"
                              v-model="ContactSale"
                              :search-input.sync="InputSearchSales"
                              item-text="fullName"
                              item-value="id"
                              :placeholder="$t('sales')"
                              outlined
                              dense
                              hide-details="auto"
                          ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                            <p>{{ $t('remark') }} : </p>
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="9">
                        <v-textarea
                            class="txtStyle"
                            v-model="Detail"
                            outlined
                            style="border-color: #ebebf2"
                            dense
                            required
                            :placeholder="$t('remark')"
                        ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
              </v-row>
              <v-divider style="color: #ebebf2"></v-divider>
            <!-- </v-form> -->
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="flagSubscription = false" text>
              <p class="mt-4 text-capitalize ml-2">{{ $t('cancel') }}</p>
            </v-btn>
            <v-btn
              @click="AddSubscription()"
              style="background-color: #126496; color: #ffffff; width: 100px"
              text
            >
              <!-- @click="Submit()" -->
              <p class="mt-4 text-capitalize">{{ $t('save') }}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="DeleteSubScriptionDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>
            
            <!-- <v-img
              style="width: 32px; height: 32px; margin-bottom: -30px"
              src="@/assets/Group7605.png"
            ></v-img> -->
          </span>
          <p style="margin-top: 10px; margin-bottom: -30px; margin-left: 20px">
           <span><v-icon color="red" large>mdi-alert-circle-outline</v-icon></span> {{ $t('Delete Subscription') }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t('areyousureyouwanttodelete') }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="DeleteSubScriptionDialog = false"
            text
            >{{ $t('cancel') }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteSubScription()"
            >{{ $t('delete') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>

<script>
import axios from "axios";
// import store from "../store";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data: () => ({
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
    flagDel: true,
    textSubscription: "",
    flagSubscription: false,
    TProductID: null,
    InputSearchCompany: null,
    SubscriptionManagementListCount: 0,
    ListCompany: [],
    Company: "",
    menuStartDate: false,
    menuEndDate: false,
    StartDate: null,
    EndDate: null,
    formatStartDate: null,
    formatEndDate: null,
    formatReminderDate: null,
    InputSearchSales: null,
    ListContactSale: [],
    ContactSale: "",
    InputSearchPackage: null,
    ListPackage: [],
    Package: "",
    Detail: "",
    url: enurl.apiUrl,
    dialogAlert: false,
    textAlert: "",
    search: "",
    Filter: {
      sale: null,
      workStatus: null,
      StartDate: null,
      EndDate: null,
      StartExpiredDate: null,
      EndExpiredDate: null
    },
    menu: false,
    date1: null,
    menu1: false,
    date2: null,
    menu2: false,

    menuStartExpiredDate: null,
    startExpiredDate: null,
    menuEndExpiredDate: null,
    endExpiredDate: null,
    menuReminderDate: null,
    ReminderDate: null, 

    FlagSearch: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [10, 15, 20],
    ProductPlanList: [],
    //ProductPlanCount: null,
    flagLoadingSend: false,
    no_run: null,
    DeleteSubScriptionDialog: false,
    CompanyID: 0,

    itemsAction: [
      { title: "View/Edit Subscription" },
      { title: "Delete Subscription" },
    ],
    paginationCount: [
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text: "#",
          sortable: false,
          align: "center",
          class: "darkprimary white--text rounded-tl-lg",
        },
        {
          text: this.$t('companyname'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text ",
        },
        // {
        //   text: "Current Package",
        //   sortable: false,
        //   align: "center",
        //   class: "darkprimary white--text ",
        // },
        // {
        //   text: "Current Usage",
        //   sortable: false,
        //   align: "center",
        //   class: "darkprimary white--text",
        // },
        {
          text: this.$t('sales'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: this.$t('startdate'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: this.$t('expiredate'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: this.$t('reminderdate'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: this.$t('status'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text",
        },
        {
          text: this.$t('more'),
          sortable: false,
          align: "center",
          class: "darkprimary white--text rounded-tr-lg",
        },
      ];
    },
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    save1(StartDate) {
      console.log(StartDate);
      this.formatStartDate = this.formatDate(StartDate);
      // this.menuEndDate = false;
      this.menuStartDate = false;
    },
    save2(EndDate) {
      let tempDate = new Date(EndDate)
      this.formatEndDate = this.formatDate(EndDate);
      tempDate.setDate(tempDate.getDate()-30);
      this.formatReminderDate = this.formatDate((new Date(tempDate).getFullYear()) +"-" + 
                                ((new Date(tempDate).getMonth()+1).toString().padStart(2,"0")) +"-"+
                                ((new Date(tempDate).getDate()).toString().padStart(2,"0")));
      
      // this.menuStartDate = false;
      this.menuEndDate = false;
    },
    saveReminderDate(ReminderDate) {
      this.formatReminderDate = this.formatDate(ReminderDate);
      // this.menuStartDate = false;
      this.menuReminderDate = false;
    },
    save3(date1) {
      this.Filter.StartDate = this.formatDate(date1);
      this.menu1 = false;
    },
    save4(date2) {
      this.Filter.EndDate = this.formatDate(date2);
      this.menu2 = false;
    },
    saveStartExpiredDate(date) {
      this.Filter.StartExpiredDate = this.formatDate(date);
      this.menuStartExpiredDate = false;
    },
    saveEndExpiredDate(date) {
      this.Filter.EndExpiredDate = this.formatDate(date);
      this.menuEndExpiredDate = false;
    },
    ClearSearchFilter(){
      let self = this;
      self.Filter.workStatus = null;
      self.Filter.sale = null;
      self.Filter.StartDate = null;
      self.Filter.EndDate = null;
      self.Filter.StartExpiredDate = null;
      self.Filter.EndExpiredDate = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getColor(e) {
      if (e == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    getColorText(e) {
      if (e == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    DownloadFile(value){
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
          DocID: value.id,
        };
        axios
          .post(`${self.url}Workflow/GetDataDownload`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              if (response.data.data != "") {
                var temp = response.data.data.split("|");
                const blobPDF = self.base64ToBlob(temp[0], 'application/pdf');
                const linkSource = URL.createObjectURL(blobPDF);
                self.downloadURI(linkSource, temp[1]);
                self.flagLoadingSend = false;
              }
            }
          });
    },

    base64ToBlob( base64, type = "application/octet-stream" ) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      return new Blob( [ arr ], { type: type } );
    },

    SelectAction(value, id) {
      let self = this;
      if (value == "View/Edit Subscription") {
        var CompanyID = id.companyID;
        var CompanyName = id.companyName;
        localStorage.setItem("CompanyID", CompanyID);
        localStorage.setItem("CompanyName", CompanyName);
        setTimeout(() => {
            self.$router.push({
            name: "ManageCustomerSubscription",
            params: { CompanyID, CompanyName },
          });
        }, 2000);
      }
      if (value == "Delete Subscription") {
        self.DeleteSubScriptionDialog = true;
        self.TProductID = id.tProductID;
        self.CompanyID = id.companyID;
        // self.flagLoadingSend = true;
        // let temp = {
        //   ID: id.tProductID,
        // };
        // axios
        //   .post(`${self.url}ProductPlan/DeleteProductPlanById`, temp)
        //   .then(function (response) {
        //     if (response.data.status == 0) {
        //       self.flagLoadingSend = false;
        //       window.location.reload();
        //     }
        //     else{
        //       self.flagLoadingSend = false;
        //     }
        //   })
        //   .catch(function (error) {
        //     self.flagLoadingSend = false;
        //     alert(error);
        //   });
      }
    },

    DeleteSubScription(){
      let self = this;
      self.flagLoadingSend = true;
        let temp = {
          ID: self.TProductID,
          CompanyID: self.CompanyID
        };
        axios
          .post(`${self.url}ProductPlan/DeleteProductPlanById`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.flagLoadingSend = false;
              self.DeleteSubScriptionDialog = false;
              window.location.reload();
            }
            else{
              self.DeleteSubScriptionDialog = false;
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
    },

    NewSubscription(){
        let self = this;
        self.Company = "";
        self.formatStartDate = "";
        self.formatEndDate = "";
        self.formatReminderDate = "";
        self.Package = "";
        self.ContactSale = "";
        self.Detail = "";
        const dateNow = (new Date(Date.now()).getFullYear()) +"-" + 
                                ((new Date(Date.now()).getMonth()+1).toString().padStart(2,"0")) +"-"+
                                ((new Date(Date.now()).getDate()).toString().padStart(2,"0"))
        self.StartDate = dateNow;
        self.EndDate = dateNow;
        self.ReminderDate = dateNow;
        self.textSubscription = self.$t('addnewsubscription');
        self.flagSubscription = true;
    },

    downloadURI(uri, name) {
      var link = document.createElement("a");
      // link.download = name;
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },

    GetProductPlan: function () {
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: self.page,
        Perpage: self.itemsPerPage,
        Search: self.search,
          Filter: {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
      };
      axios
        .post(`${self.url}ProductPlan/GetTProductCompanyGroupCompanyList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProductPlanList = response.data.data.listData;
            self.SubscriptionManagementListCount = response.data.data.dataCount;
            self.pageCount = response.data.data.count;
            //self.ProductPlanCount = response.data.data.dataCount;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.ProductPlanList = [];
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          alert(error);
        });
    },

    ChangePageProductPlan: function (value) {
      let self = this;
      if(new Date(self.date1) <= new Date(self.date2) || (self.date1 == null && self.date2 == null))
      {
      self.flagLoadingSend = true;
        let temp = {
          Search: self.search,
          Page: value,
          Perpage: self.itemsPerPage,
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null}
        };
        axios
          .post(`${self.url}ProductPlan/GetTProductCompanyGroupCompanyList`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.ProductPlanList = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.no_run = (self.page - 1) * self.itemsPerPage;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.ProductPlanList = [];
              self.pageCount = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t('nodatafound');
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
      }
    },

    ChangePerPageProductPlan: function (value) {
      let self = this;

      if(new Date(self.date1) <= new Date(self.date2) || (self.date1 == null && self.date2 == null))
      {
      self.flagLoadingSend = true;
        let tempdata = {
          Search: self.search,
          Page: 1,
          Perpage: value,
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null}
        };
        axios
          .post(`${self.url}ProductPlan/GetTProductCompanyGroupCompanyList`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.ProductPlanList = response.data.data.listData;
              self.pageCount = response.data.data.count;
              self.page = 1;
              self.no_run = (self.page - 1) * self.itemsPerPage;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.ProductPlanList = [];
              self.pageCount = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t('nodatafound');
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
      }
    },
    SearchFilter() {
      let self = this;
      
      if((new Date(self.date1) <= new Date(self.date2) || (self.date1 == null && self.date2 == null)) && (new Date(self.startExpiredDate) <= new Date(self.endExpiredDate) || (self.startExpiredDate == null && self.date2 == null)))
      {        
        self.flagLoadingSend = true;
        let temp = {
          Page: 1,
          Perpage: self.itemsPerPage,
          Search: self.search,
          Filter: self.FlagSearch == true ? self.Filter : {sale: null, workStatus: null, StartDate: null, EndDate: null, StartExpiredDate: null, EndExpiredDate: null}
        };

        self.searchTemp = temp;

        axios
        .post(`${self.url}ProductPlan/GetTProductCompanyGroupCompanyList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProductPlanList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.SubscriptionManagementListCount = response.data.data.dataCount;
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCount = 0;
            self.ProductPlanList = [];
          }
        })
        .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
        });
      }
      else
      {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t('pleaseselectthecorrectstartdateandenddate');
        self.flagAlertSend = true;
      }
    },
    async AddSubscription () {
      let self = this;
      if((self.Company != null && self.Company != "") && (self.Package != null && self.Package != "") && (self.ContactSale != null && self.ContactSale != ""))
      {
        let TempSend = {
            CompanyID : self.Company.companyID,
            StartDate : self.formatStartDate,
            EndDate : self.formatEndDate,
            ReminderDate : self.formatReminderDate,
            ProductPlanID : self.Package.id,
            SalesID : self.ContactSale.id,
            Detail : self.Detail,
        }
        // console.log(TempSend);
        await axios
        .post(`${self.url}ProductPlan/AddSubscription`, TempSend)
        .then(function (response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = self.$t('successfully');
              self.flagAlertSend = true;
              self.flagSubscription = false;
              window.location.reload();
            }
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          self.flagSubscription = false;
          // alert(error);
        });
      }
      else{
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t('pleasefillintheinformationcompletely');
        self.flagAlertSend = true;
      }
    },

    UTCToLocal(dateStr, format) {
      if (typeof dateStr == "string") {
        let newDateStr = dateStr;
        if (!newDateStr.match(/Z$/)) {
          newDateStr += "Z";
        }
        return moment(newDateStr).format(format);
      }
      return "";
    },
  },

  watch: {
      InputSearchCompany: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}Company/RetrieveCompanyByCompanyName`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListCompany = response.data.data;
              } else {
                self.ListCompany = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListCompany = [];
              alert(error);
            });
        }
      }
    },
    InputSearchSales: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}Sales/GetSalesBySalesNameAsync`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListContactSale = response.data.data;
              } else {
                self.ListContactSale = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListContactSale = [];
              alert(error);
            });
        }
      }
    },
    InputSearchPackage: function (val) {
      if (val != null && val != "") {
        if (val.length >= 2) {
          let self = this;
          self.flagLoadingSend = true;
          let tempdata = {
            Search: val,
          };
          axios
            .post(`${self.url}ProductPlan/GetDataProductPlanByPlanName`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.ListPackage = response.data.data;
              } else {
                self.ListPackage = [];
              }
              self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.ListPackage = [];
              alert(error);
            });
        }
      }
    },
  },

  mounted() {
    localStorage.setItem("CompanyID", "");
    localStorage.setItem("CompanyName", "");
    this.GetProductPlan();
    const elements = document.querySelectorAll(".v-pagination__navigation");
    
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = this.$t('next');
      } else {
        element.textContent = this.$t('previous');
      }
    }
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1030px) and (max-width: 1409px) {
  .per_page {
    margin-left: -5rem !important;
  }
  .v-select > .v-input__control > .v-input__slot {
    width: 80px !important;
  }
}
tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}
.v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}
.v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
*>>>.v-pagination__item{
  margin: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1)
}
*>>>.v-pagination__navigation{
  margin: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 80px !important;
  height: 34px !important;
  border-radius: unset !important;
  box-shadow: 0px 0px 6px #0000000A !important;
}

.v-text-field--outlined >>> fieldset {
  border: 1px solid #EBEBF2
}

.downArrowAndUpArrow >>>.v-icon.v-icon{
  color: #126496;
}
</style>
<style lang="scss">
.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>