import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

// const messages = {
    
//     th: {
//         companymanagement:"การจัดการบริษัท",
//         subscriptionmanagement:"การจัดการสมัครสมาชิก",
//         historylog:"ประวัติเข้าสู่ระบบ",
//         syncmanagement:"การจัดการการซิงค์",
//         notificationconfiguration:"การกำหนดค่าการแจ้งเตือน",
//         masterproductplan:"แผนผลิตภัณฑ์หลัก",
//         salesmanagement:"การจัดการการขาย",
//         rolemanagement:"การจัดการบทบาท",
//         templatecontract:"สัญญาแม่แบบ",
//         report:"รายงาน",
//         usermanual:"คู่มือการใช้งาน",
//         mailboardcast:"บอร์ดคาสต์ทางไปรษณีย์",
//         totalrecords:"บันทึกทั้งหมด",
//         running:"วิ่ง",
//         removed:"ลบออก",
//         newcompany:"+ บริษัทใหม่",
//         searchcompanycodecompanynameemailandtelephoneno:"ค้นหารหัสบริษัท ชื่อบริษัท อีเมล และหมายเลขโทรศัพท์",
//         companycode:"รหัสบริษัท",
//         date:"วันที่",
//         description:"ลักษณะ",
//         usermanagement: "การจัดการผู้ใช้",
//         username: "ชื่อผู้ใช้",
//         email: "อีเมล",
//         search: "ค้นหา",
//         add: "เพิ่ม",
//         phonenumber: "หมายเลขโทรศัพท์",
//         employeename: "ชื่อพนักงาน",
//         usertype: "ประเภทผู้ใช้e",
//         edit: "แก้ไข",
//         delete: "ลบ",
//         users: "ผู้ใช้",
//         usergroup: "กลุ่มผู้ใช้",
//         permissionmanagement: "การจัดการสิทธิ์",
//         menumanagement: "การจัดการเมนู",
//         calendar: "ปฏิทิน",
//         log: "เข้าสู่ระบบ",
//         setting: "การตั้งค่า",
//         groupname: "ชื่อกลุ่ม",
//         permissionname: "ชื่อสิทธิ์",
//         save: "บันทึก",
//         close: "ปิด",
//         update: "ปรับปรุง",
//         firstname: "ชื่อจริง",
//         lastname: "นามสกุล",
//         indicatesrequiredfield: "แสดงถึงฟิลด์ที่จำเป็น",
//         userprofile: "ประวัติผู้ใช้",
//         logout: "ประวัติผู้ใช้",
//         password: "รหัสผ่าน",
//         rememberme: "จดจำฉัน",
//         forgotpassword: "ลืมรหัสผ่าน",
//         login: "เข้าสู่ระบบ",
//         successMessage: "เข้าสู่ระบบเรียบร้อยแล้ว",
//         adduseringroup:"เพิ่มผู้ใช้ในกลุ่ม",
//         menuname:"ชื่อเมนู",
//         number:"จำนวน",
//         addgroupinmenu:"เพิ่มกลุ่มในเมนู"



//     },
//     en: {
//         companymanagement:"Company Management",
//         subscriptionmanagement:"Subscription Management",
//         historylog:"HistoryLog",
//         syncmanagement:"Sync Management",
//         notificationconfiguration:"Notification Configuration",
//         masterproductplan:"Master Product Plan",
//         salesmanagement:"Sales Management",
//         rolemanagement:"Role Management",
//         templatecontract:"Template Contract",
//         report:"Report",
//         usermanual:"User Manual",
//         mailboardcast:"Mail Boardcast",
//         totalrecords:"Total records",
//         running:"Running",
//         removed:"Removed",
//         newcompany:"+ New Company",
//         searchcompanycodecompanynameemailandtelephoneno:"search company code, company name, email and telephone no",
//         companycode:"Company Code",
//         date:"Date",
//         description:"Description",
//         usermanagement: "User Management",
//         username: "User Name",
//         email: "Email",
//         search: "Search",
//         add: "Add",
//         phonenumber: "Phone Number",
//         employeename: "Employee Name",
//         usertype: "User Type",
//         edit: "Edit",
//         delete: "Delete",
//         users: "Users",
//         usergroup: "User Group",
//         permissionmanagement: "Permission Management",
//         menumanagement: "Menu Management",
//         calendar: "Calendar",
//         log: "Log",
//         setting: "Setting",
//         groupname: "Group Name",
//         adduseringroup: "Add user in group",
//         permissionname: "Permission Name",
//         save: "Save",
//         close: "Close",
//         update: "Update",
//         firstname: "First Name",
//         lastname: "Last Name",
//         indicatesrequiredfield: "Indicates required field",
//         userprofile: "User Profile",
//         logout: "Log Out",
//         password: "Password",
//         rememberme: "Remember Me",
//         forgotpassword: "Forgot Password",
//         login: "Login",
//         successMessage: "Logged In Successfully",
//         menuname:"Menu Name",
//         number:"No",
//         addgroupinmenu:"Add group in menu"
//     }
// }

function loadLocaleMessages() {
    const locales = require.context(
      '../locales',
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
}

const i18n = new VueI18n({
    locale: "en", // set locale
    fallbackLocale: "th", // set fallback locale
    messages: loadLocaleMessages() // set locale messages
});

Vue.filter('myLocale', function (value) {
    return i18n.t(value)
})

export default i18n;