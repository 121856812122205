<template>
  <nav style="z-index:3">
    <v-toolbar color="#FFFFFF">
      <!-- <div class="d-flex align-center"> -->
      <v-img
        alt="Fusion Logo"
        class="shrink mr-2 app"
        contain
        :src="images.logo"
        transition="scale-transition"
        width="130"
      />
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="margin-left:3rem;"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-btn fab small color="primary" v-on="{  ...menu }" class="mr-4">
            <flag :iso="currentflag" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="entry in languages"
            :key="entry.title"
            @click="changeLocale(entry.language,entry.flag)"
          >
            <v-list-item-action>
              <flag :iso="entry.flag" v-bind:squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{entry.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>-->
      <v-row>
        <v-col 
          cols="6"
          >
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-center align-center"
          >  
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text dark v-on="on" >
                <flag :iso="currentflag" style="font-size:25px"  v-bind:squared="false"/>
              </v-btn>
            </template>
            <v-list>
                <v-list-item 
                  v-for="entry in languages" 
                  :key="entry.title"
                  @click="changeLocale(entry.language, entry.flag)"
                  >
                  <v-list-item-action>
                    <flag :iso="entry.flag" v-bind:squared="false"/>
                  </v-list-item-action>
                  <v-list-item-title>{{ entry.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
          </v-menu> 
        </v-col>
        <v-col 
          cols="2"
          class="d-flex justify-center align-center"
          >
          <v-menu offset-y>
            <template class="white-color" v-slot:activator="{ on: menu }">
              <!-- <v-btn v-on="{  ...menu }" style="background-color: transparent !important">
                <span>{{username}}</span>
                <span>
                  <v-icon>mdi-menu-down</v-icon>
                </span>
              </v-btn>-->
              <v-btn icon v-on="{  ...menu }">
                <v-icon color="#126496">mdi-menu-down</v-icon>
              </v-btn>
              <div>
                <span style="font-size:24px;">{{username}}</span>
                <br />
                <span style="color:#126496;">{{username}}</span>
              </div>
              <img
                src="@/assets/MaskGroup53.png"
                style="padding-right:5px;border-radius: 50%;overflow: hidden;display:inline-block;border-color:#126496"
              />
            </template>
            <v-list>
              <v-list-item class="list">
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title @click="UserProfile">{{$t('userprofile')}}</v-list-item-title>
              </v-list-item>
              <v-list-item class="list" @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('logout')}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>   
    </v-toolbar>

    <!-- <v-navigation-drawer
      fixed
      app
      style="max-height: calc(100% - 70px);margin-top:70px;background-image: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #E1F0FE, #E1F0FE);"
      v-model="drawer"
      absolute
    >-->
    <v-navigation-drawer
      fixed
      app
      style="min-height: calc(100% - 60px);margin-top:65px;background-color:#ffffff;"
      v-model="drawer"
      absolute
    >
      <v-list>
        <v-list-item-group active-class="border white--text primary">
          <v-list-item
          v-for="(item,id) in items"
          :key="id"
          link
          router
          :to="item.route"
        >
          <v-list-item-icon>
            <!-- <v-icon>{{ item.icon }}</v-icon> -->
            <img :src="item.image_active" style="width: 20px;" v-if="$route.path == item.route"/>
            <img :src="item.image" style="width: 20px;" v-else/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title | myLocale }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list-item-group>       
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
import i18n from "@/plugins/i18n";
import axios from "axios";
import store from "../store";
import enurl from "@/api/environment";
export default {
  data() {
    return {
      url: enurl.apiUrl,
      show: false,
      username: store.state.username,
      usertype: store.state.usertype,
      drawer: null,
      images: {
        logo: require("@/assets/Group 7673@2x.png"),
      },
      currentflag: "en",
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "th", language: "th", title: "ไทย" },
      ],
      users: [],
      items: [
        {
          title: "companymanagement",
          icon: "mdi-clipboard",
          route: "/CompanyManagement",
          image_active: require('@/assets/images/home-active.svg'),
          image: require("@/assets/images/home.svg")
        },
        {
          title: "subscriptionmanagement",
          icon: "mdi-clipboard",
          route: "/SubscriptionManagement",
          image_active: require('@/assets/images/document-signed-active.svg'),
          image: require("@/assets/images/document-signed.svg")
        },
        {
          title: "historylog",
          icon: "mdi-clock-outline",
          route: "/HistoryLog",
          image_active: require('@/assets/images/time-past-active.svg'),
          image: require("@/assets/images/time-past.svg")
        },
        // {
        //   title: "Sync Management",
        //   icon: "mdi-sync",
        //   route: "/SyncManagement",
        //   image_active: require('@/assets/images/data-transfer-active.svg'),
        //   image: require("@/assets/images/data-transfer.svg")
        // },
        // {
        //   title: "Notification Configuration",
        //   icon: "mdi-bell",
        //   route: "/NotiConfig",
        //   image_active: require('@/assets/images/bell-active.svg'),
        //   image: require("@/assets/images/bell.svg")
        // },
        // {
        //   title: "Approve Management",
        //   icon: "mdi-clipboard",
        //   route: "/ApproveManagement",
        // },
        {
          title: "masterproductplan",
          icon: "mdi-clipboard",
          route: "/MasterProductPlan",
          image_active: require('@/assets/settingsActive.png'),
          image: require("@/assets/settings.png")
        },
        {
          title: "salesmanagement",
          icon: "mdi-clipboard",
          route: "/SalesManagement",
          image_active: require('@/assets/salesActive.png'),
          image: require("@/assets/sales.png")
        },
        {
          title: "rolemanagement",
          icon: "mdi-clipboard",
          route: "/RoleManagement",
          image_active: require('@/assets/images/document-active.svg'),
          image: require("@/assets/images/document.svg")          
        },
        {
          title: "templatecontract",
          icon: "mdi-file-document-edit",
          route: "/TemplateContract",
          image_active: require('@/assets/images/file-document-edit-outline.svg'),
          image: require("@/assets/images/file-document-edit.svg")          
        },
        {
          title: "report",
          icon: "mdi-clipboard-text",
          route: "/ReportManagement",
          image_active: require('@/assets/images/exclamation-active.svg'),
          image: require("@/assets/images/exclamation.svg")
        },
        {
          title: "usermanual",
          icon: "mdi-book-outline",
          route: "/UserManual",
          image_active: require('@/assets/images/book-account-outline.svg'),
          image: require("@/assets/images/book-account.svg")
        },
        {
          title: "mailboardcast",
          icon: "mdi-book-outline",
          route: "/MailBoardcast",
          image_active: require('@/assets/images/mail-boardcast-active.svg'),
          image: require("@/assets/images/mail-boardcast.svg")
        },
      ],
    };
  },
  methods: {
    profileImageSync() {
      let self = this;
      let tempData = {
        userName: self.username,
        password: self.password,
      };
      axios.post(`${self.url}Login/Login`, tempData).then(function (response) {
        self.imageUrl = response.data.data.pathImage;
      });
    },

    changeLocale(locale, flag) {
      this.currentflag = flag;
      i18n.locale = locale;
      sessionStorage.setItem("flag", flag);
      sessionStorage.setItem("locale", locale);
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          localStorage.removeItem("token");
          this.$router.push("/login");
          sessionStorage.removeItem("permissionID");
        })

        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    },
    UserProfile() {
      let self = this;
      self.$router.push("/userprofile");
    },
    homepage() {
      let self = this;
      self.$router.push("/usermanagement");
    },
  },
  mounted() {
    this.currentflag = sessionStorage.getItem("flag");
    //i18n.locale = sessionStorage.getItem("locale");

    if(this.currentflag == "us"){
      i18n.locale = "en";
    }else{
      i18n.locale = "th";
    }
  },
};
</script>
<style>
.app {
  margin-left: 2rem;
  cursor: pointer;
}
.list {
  cursor: pointer;
}
.apptitle {
  font-family: "Roboto", sans-serif;
  font-size: 20px !important;
}
.v-list-item__content {
  font-family: "Roboto", sans-serif;
  font-size: 20px !important;
}
.white-color {
  background-color: white;
}
</style>