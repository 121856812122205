<template>
  <div>
    <v-card-text class="card-style">
      <h3 class="text--black">{{ $t('report') }}</h3>
      <br />
      <v-expansion-panels class="card--margin" v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header style>
            <template v-slot:actions>
              <v-icon
                size="30"
                style="background-color:#1F3781;border-radius:50%;opacity: 1"
                color="white"
              >mdi-chevron-down</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report1'})"
            >{{ $t('report1') }}</v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text 
              @click="$router.push({path: '/report2'})"
            >{{ $t('report2') }}</v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report3'})"
            >{{ $t('report3') }}</v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report4'})"
            >{{ $t('report4') }}</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report5'})"
            >{{ $t('report5') }}</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report6'})"
            >{{ $t('report6') }}</v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content>
            <v-btn
              color="#3E8EF7"
              class="text-decoration-underline r-btn"
              text
              @click="$router.push({path: '/report7'})"
            >{{ $t('report7') }}</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </div>
</template>
<script>

export default {
  props: {},
  data() {
    return {
      panel: [0]
    };
  },
};
</script>
<style scoped>
</style>
