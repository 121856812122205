<template>
  <div :style="{'margin-bottom':!$vuetify.breakpoint.xl && !$vuetify.breakpoint.lg?'-20px':''}">
    <v-container fluid>
      <v-flex :style="{margin:!$vuetify.breakpoint.xl? '0':'',padding:!$vuetify.breakpoint.xl? '0':''}">
        <v-row>
          <v-col cols="12" md="3">
            <p style="color: #444444;font-size: 20px">{{ $t('editcompany') }}</p>
            <p style="color: #126496; font-size: 14px">{{ $t('generalinformation') }}</p>
          </v-col>
          <v-col cols="12" md="9"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="8" lg="8">
            <p class="header-title">{{ $t('companyname')}}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('companyname')"
              v-model="CompanyName"
            />
            <br />
            <span v-if="v_CompanyName" class="validation-class">{{ $t('pleasefillcompanyname') }}</span>
          </v-col>
          <!-- <v-col cols="12" md="4"></v-col> -->
          <v-col cols="12" sm="5" md="4" lg="4">
            <p class="header-title">{{ $t('companycode') }}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('companycode')"
              v-model="CompanyCode"
            />
            <br />
            <span v-if="v_CompanyCode" class="validation-class">{{ $t('pleasefillcompanycode') }}</span>
          </v-col>
          <v-col cols="12" md="2" lg="1"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="5">
            <p class="header-title">{{ $t('telephonenumber') }}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('telephonenumber')"
              v-model="TelephoneNumber"
            />
            <br />
            <span v-if="v_Phone" class="validation-class">{{ $t('pleasefilltelephonenumber') }}</span>
          </v-col>
          <v-col cols="12" sm="6" md="7" lg="7">
            <p class="header-title">{{ $t('email') }}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('email')"
              v-model="Email"
            />
            <br />
            <span v-if="v_Email" class="validation-class">{{ $t('pleasefillemail') }}</span>
          </v-col>
          <v-col cols="12" md="2"></v-col>
          <v-col cols="12" md="2"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm ="5" md="5" lg="5">
            <p class="header-title">{{ $t('address') }}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('address')"
              v-model="Address"
            />
            <br />
            <span v-if="v_Address" class="validation-class">{{ $t('pleasefilladdress') }}</span>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4">
            <p class="header-title">{{ $t('country') }}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('country')"
              v-model="Country"
            />
            <br />
            <span v-if="v_Country" class="validation-class">{{ $t('pleasefillcountry') }}</span>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3">
            <p class="header-title">{{ $t('city') }}</p>
            <input
              class="input-border"
              style="width: 100%"
              type="text"
              :placeholder="$t('city')"
              v-model="City"
            />
            <br />
            <span v-if="v_City" class="validation-class">{{ $t('pleasefillcity') }}</span>
          </v-col>
          <v-col cols="12" md="1"></v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="5" md="5">
            <p style="color: #126496; font-size: 14px">{{ $t('logincredentials') }}</p>
            <p class="header-title">{{ $t('userid') }}</p>
            <v-text-field
              style="width: 100%"
              type="text"
              :placeholder="$t('userid')"
              v-model="UserID"
              hide-details
              disabled dense 
            />
            <br />
            <span v-if="v_UserID" class="validation-class">{{ $t('pleasefilluserid') }}</span>
          </v-col>
          <v-col cols="12" md="7"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="5" md="5" lg="5">
            <p class="header-title">{{ $t('password') }}</p>
            <input
              id="password"
              class="input-border"
              style="width: 100%"
              type="password"
              :placeholder="$t('password')"
              v-model="Password"
            />
            <v-icon
              v-show="flagPassword"
              style="margin-left: -30px; cursor: pointer;"
              @click="TogglePassword()"
            >mdi-eye</v-icon>
            <v-icon
              v-show="!flagPassword"
              style="margin-left: -30px; cursor: pointer;"
              @click="TogglePassword()"
            >mdi-eye-off</v-icon>
            <br />
            <span v-if="v_Password" class="validation-class">{{ $t('pleasefillpassword') }}</span>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3">
            <v-btn
              color="#126496"
              style="width:14rem; color: white; font-size: 12px;margin-top:34px;"
              @click="GenerateDataPassword()"
            >{{ $t('generatepassword') }}</v-btn>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
        </v-row>

        <v-card-actions>
        <input type="checkbox" v-model="Status" />
        <span style="padding-left:7px;">{{ $t('status') }}</span>
        <v-spacer></v-spacer>
        </v-card-actions>

        <div class="lock_button_container">
          <div class="lock_button"> 
            <v-icon v-if="lockStatus" @click="lockStatus = false">mdi-lock-outline</v-icon>
            <v-icon v-else @click="lockStatus = true">mdi-lock-open-variant-outline</v-icon>
          </div>
          <span class="ml-1">{{ $t('accountlocked') }}</span> 
        </div>

        <!-- <v-row>
          <v-col cols="12" md="3">
            <p style="color: #126496; font-size: 14px">Packet Management</p>
            <p class="header-title">Limit User</p>
            <input class="input-border" style="width: 268px" type="text" placeholder="Limit User" />
          </v-col>
          <v-col cols="12" md="3" style="margin-top: 36px;">
            <p class="header-title">Sign Work Flow Limit</p>
            <input
              class="input-border"
              style="width: 268px"
              type="text"
              placeholder="Sign Work Flow Limit"
            />
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2">
            <p class="header-title">Expires Date</p>
            <input class="input-border" style="width: 240px" type="text" placeholder="DD/MM/YYYY" />
          </v-col>
          <v-col cols="12" md="1" style="margin-top: 20px;">
            <v-checkbox hide-details></v-checkbox>
          </v-col>
          <v-col cols="12" md="3" style="margin-top: 36px;">
            <p style="color: #126496; font-size: 14px">Expires on DD/MM/YYYY</p>
          </v-col>
          <v-col cols="12" md="6"></v-col>
        </v-row> -->
        <!-- <v-divider></v-divider> -->

        <div class="darkprimary--text mt-5 text-h6" >{{ $t('veracityservices') }}</div>  
        <v-data-table
          class="mt-5 text-h5"
          :items="feature"
          hide-default-footer
          style="color: #444444;box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 7%), 0px 1px 1px 1px rgb(0 0 0 / 7%), 0px 1px 1px 0px rgb(0 0 0 / 7%)"
          item-key="no"
          group-by="groupNo"
          hide-details 
          disable-pagination
        >
         <template v-slot:header > 
          <thead >
            <tr style="background-color: #126496;">
              <th style="color: aliceblue;">{{  $t( headers[1].text )}}</th>
              <th style="color: aliceblue;">{{  $t( headers[2].text )}}</th>
            </tr>
          </thead>
         </template>
          <template v-slot:group.header="{items, isOpen, toggle, index }" >
            <th @click="toggle" :colspan="1">
              <td>
                <v-subheader class="px-0"> 
                <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon> 
                  {{ $t( items[0].category ) }}
                </v-subheader>
              </td> 
            </th>
            <th :colspan="1">
              <td>
                <center>
                  <v-switch dense inset v-model="groupAction[items[0].groupNo-1].action" class="pa-0 px-0" @click="groupBTN(items[0].groupNo-1)"></v-switch>
                </center>
              </td>  
            </th>
          </template>

          <template v-slot:item="{ item,index }">
            <tr>
              <td>
                <span class="pl-5">{{ $t( item.name ) }}</span>
              </td>
              <td>
                <center><v-switch v-model="item.action" @click="featureBTN(item)" inset dense hide-details class="my-0 py-0"> </v-switch></center>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-col cols="12" md="3">
          <v-btn
            color="#1976D2"
            class="mt-3"
            style="width:14rem; color: white"
            @click="SetDefault()"
          >{{ $t('setdefault') }}</v-btn>
        </v-col>
 <!-- <v-divider></v-divider> -->

        <v-row>
          <v-col cols="3" sm="3" md="3" lg="3">
            <v-btn
              color="#B8B8B8"
              class="mt-10"
              style="padding-left:20px; padding-right:20px; color: white; font-size: 12px;"
              @click="GotoCompanyManagement()"
            >{{ $t('cancel') }}</v-btn>
          </v-col>
          <v-col cols="3" sm="6" md="6" lg="5"></v-col>
          <v-col cols="4" sm="3" md="3" lg="3">
            <v-btn
              color="#1976D2"
              class="mt-10"
              style="padding-left:20px; padding-right:20px;  color: white"
              @click="EditCompany()"
            >{{ $t('editcompany') }}</v-btn>
          </v-col>
          <!-- <v-col cols="12" md="2"></v-col> -->
        </v-row>
      </v-flex>
    </v-container>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <br />
    <br />
    <br />
    <br />
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Footer from "./../components/Footer.vue";
// import store from "../store";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Footer,
    Alert,
    Loading,
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    size: {
      type: String,
      default: "10"
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#"
    },
    placeholder: {
      type: String,
      default: "Password"
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    dialog: false,
    userList: [],
    url: enurl.apiUrl,
    CompanyID: 0,
    CompanyName: "",
    CompanyCode: "",
    TelephoneNumber: "",
    Email: "",
    Address: "",
    Country: "",
    City: "",
    UserID: "",
    Password: "",
    Status: false,
    flagPassword: false,
    v_CompanyName: false,
    v_CompanyCode: false,
    v_Phone: false,
    v_Email: false,
    v_Country: false,
    v_City: false,
    v_Address: false,
    v_UserID: false,
    v_Password: false,
    checkphone: false,
    checkemail: false,
    flagAlertSend: false,
    flagLoadingSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",

    Userid: 0,
    EmployeeID: 0,

    headers: [
          {
            text: 'No',
            align: 'start',
            value: 'groupNo',
            sortable: false,
            class: "darkprimary white--text", 
          },
          { text: 'feature', 
            value: 'name', 
            align: 'start', 
            sortable: false,
            class: "darkprimary white--text", 
          },
          { text: 'action', 
            value: 'action', 
            align: 'start',
            sortable: false,
            class: "darkprimary white--text", 
          },
        ],
    groupAction:[
      {
        no: 1,
        text: 'Self Sign',
        action: false,
      },
      {
        no: 2,
        text: 'Workflow & Workflow Templates',
        action: false,
      },
      {
        no: 3,
        text: 'Email Templates',
        action: false,
      },
      {
        no: 4,
        text: 'Document Templates',
        action: false,
      },
      {
        no: 5,
        text: 'Contract Templates',
        action: false,
      },
    ],
    feature: [
       // "Self Sign"
      {
        no: 1,
        groupNo:1,
        featureID:1,
        // category: 'Self Sign',
        name: 'mergepdf',
        action: false,
      },
      {
        no: 2,
        groupNo:1,
        featureID:2,
        // category: 'Self Sign',
        name: 'watermark',
        action: false,
      },
      // "Workflow & Workflow Templates"
      {
        no: 3,
        groupNo: 2,
        featureID: 3,
        // category: 'Workflow & Workflow Templates',
        name: 'tabworkflowtemplates',
        action: false,
      },
      {
        no: 4,
        groupNo: 2,
        featureID: 1,
        // category: 'Workflow & Workflow Templates',
        name: 'mergepdf',
        action: false,
      },
      {
        no: 5,
        groupNo: 2,
        featureID: 4,
        // category: 'Workflow & Workflow Templates',
        name: 'effectivedate',
        action: false,
      },
      {
        no: 6,
        groupNo: 2,
        featureID: 5,
        // category: 'Workflow & Workflow Templates',
        name: 'expirationdate',
        action: false,
      },
      {
        no: 7,
        groupNo: 2,
        featureID: 6,
        // category: 'Workflow & Workflow Templates',
        name: 'setthedateandtimeforsendingtheemail',
        action: false,
      },
      {
        no: 8,
        groupNo: 2,
        featureID: 7,
        // category: 'Workflow & Workflow Templates',
        name: 'sendanemailtorequiresign',
        action: false,
      },
      {
        no: 9,
        groupNo: 2,
        featureID: 8,
        // category: 'Workflow & Workflow Templates',
        name: 'sendanemailtosuccessfullysigned',
        action: false,
      },
      {
        no: 10,
        groupNo: 2,
        featureID: 9,
        // category: 'Workflow & Workflow Templates',
        name: 'sendanemailbacktocreator',
        action: false,
      },
      {
        no: 11,
        groupNo: 2,
        featureID: 2,
        // category: 'Workflow & Workflow Templates',
        name: 'watermark',
        action: false,
      },
      // // "Document Templates"
      // {
      //   category: 'Document Templates',
      //   name: 'Gingerbread',
      //   dairy: 'No',
      // },
      // "Email Templates"
      {
        no: 12,
        groupNo: 3,
        featureID: 10,
        // category: 'Email Templates',
        name: 'tabemailtemplates',
        action: false,
      },
      // "Document Templates"
      {
        no: 13,
        groupNo: 4,
        featureID: 11,
        // category: 'Document Templates',
        name: 'tabdocumenttemplate',
        action: false,
      },
      {
        no: 14,
        groupNo: 4,
        featureID: 2,
        // category: 'Document Templates',
        name: 'watermark',
        action: false,
      },
      //Contract Templates
      {
        no: 15,
        groupNo: 5,
        featureID: 12,
        // category: 'Contract Templates',
        name: 'tabcontracttemplates',
        action: false,
      },
    ],
    lockStatus: false,
  }),

  methods: {
    groupBTN(e){
      let self = this;
      self.feature = self.feature.map((v) => ({ 
          ...v,
          action: v.groupNo == self.groupAction[e].no && self.groupAction[e].action == true ? true :  
                  v.groupNo != self.groupAction[e].no ? v.action : false ,
      }))
    },
    featureBTN(e){
      let self = this;
      let filter = self.feature.filter(x=>x.groupNo == e.groupNo)
      self.groupAction = self.groupAction.map((v) => ({ 
          ...v,
          action: e.groupNo == v.no && filter.find(x=>x.action && v.no == x.groupNo) != undefined ? true : 
                  e.groupNo != v.no ? v.action : false ,
      }))
    },
    SetDefault(){
      let self = this; 
      let finder = [1,3,4,12,13,15]
      self.feature = self.feature.map((v) => ({ 
          ...v,
          action: finder.find(x=> x == v.no) ? true : false,
      }))
      self.groupAction = self.groupAction.map((v) => ({
        ...v,
        action: self.feature.find(x=>x.groupNo == v.no && x.action == true) ? true : false,  
      }))
    },
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    FillDataEditCompany(temp) {
      let self = this;
      self.CompanyID = temp.companyID;
      self.CompanyName = temp.companyName;
      self.CompanyCode = temp.companyCode;
      self.TelephoneNumber = temp.telephoneNo;
      self.Email = temp.email;
      self.Address = temp.address;
      self.Country = temp.country;
      self.City = temp.city;
      self.Status = temp.status;
      self.flagLoadingSend = true;
      try {
        let tempsent = {
          id: parseInt(temp.companyID)
        };

        axios
          .post(`${self.url}Company/GetUserCompany`, tempsent)
          .then(function(response) {
            self.UserID = response.data.data.username;
            self.Password = response.data.data.password;
            self.Userid = response.data.data.userID;
            self.EmployeeID = response.data.data.employeeID; 
            self.lockStatus = response.data.data.lockStatus;
            let listdata = response.data.data.feature;
            self.feature = self.feature.map((v) => 
            {
              let tempData = listdata.find(x=>x.groupID == v.groupNo && x.featureID == v.featureID)
                v.action = tempData.statusFlag;
                v.category = tempData.groupName;
                self.groupAction = self.groupAction.map((g) => ({ 
                  ...g,
                  action: v.groupNo == g.no && v.action == true || g.action == true ? true : 
                          v.groupNo != g.no ? g.action : false ,
                }))
                return {
                  ...v,
                }
            }) 
            self.flagLoadingSend = false
          })
          .catch(function(error) {
            console.log(error);
            self.flagLoadingSend = false
          });
      } catch (error) {
        console.log(error);
        self.flagLoadingSend = false
      }
    },

    GenerateUserID: function() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < 6; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },

    GenerateDataPassword(){
        let self = this;
        self.Password = self.GeneratePassword();
    },

    GeneratePassword: function() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },

    TogglePassword() {
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        this.flagPassword = false;
      } else {
        x.type = "password";
        this.flagPassword = true;
      }
    },

    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) || "invalid";
    },

    GotoCompanyManagement() {
      let self = this;
      self.$router.push("/companymanagement");
    },

    EditCompany() {
      var self = this;
      if (
        self.CompanyName == "" ||
        self.CompanyCode == "" ||
        self.TelephoneNumber == "" ||
        self.Email == "" ||
        self.Address == "" ||
        self.Country == "" ||
        self.City == "" ||
        self.UserID == "" ||
        self.Password == "" ||
        self.TelephoneNumber.length < 9 || self.TelephoneNumber.length > 11 ||
        self.validateEmail(self.Email) == "invalid"
      ) {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t('pleasefillintheinformationcompletely');
        self.flagAlertSend = true;
        if (this.CompanyName == "") {
          this.v_CompanyName = true;
        } else {
          this.v_CompanyName = false;
        }
        if (this.CompanyCode == "") {
          this.v_CompanyCode = true;
        } else {
          this.v_CompanyCode = false;
        }
        if (this.TelephoneNumber == "") {
          this.v_Phone = true;
        } else {
          if (this.TelephoneNumber != null) {
            if (
              this.TelephoneNumber.length < 9 ||
              this.TelephoneNumber.length > 11
            ) {
              this.checkphone = true;
            } else {
              this.checkphone = false;
            }
            this.v_Phone = false;
          }
        }
        if (this.Email == "") {
          this.v_Email = true;
        } else {
          if (self.validateEmail(self.Email) == "invalid") {
            this.checkemail = true;
          } else {
            this.checkemail = false;
          }
          this.v_Email = false;
        }
        if (this.Country == "") {
          this.v_Country = true;
        } else {
          this.v_Country = false;
        }
        if (this.City == "") {
          this.v_City = true;
        } else {
          this.v_City = false;
        }
        if (this.Address == "") {
          this.v_Address = true;
        } else {
          this.v_Address = false;
        }
        if (this.UserID == "") {
          this.v_UserID = true;
        } else {
          this.v_UserID = false;
        }
        if (this.Password == "") {
          this.v_Password = true;
        } else {
          this.v_Password = false;
        }
      } else {
        let listFeature = self.feature.map((v)=> {
            return{
              groupID: v.groupNo,
              featureID: v.featureID,
              isActive: v.action,
            }
        })
        var temp = {
          companyID: self.CompanyID,   
          companyName: self.CompanyName,
          companyCode: self.CompanyCode,
          telephoneNo: self.TelephoneNumber,
          email: self.Email,
          country: self.Country,
          city: self.City,
          address: self.Address,
          status: self.Status,
          featureUpdate: listFeature,
          employeeExtJoinRequestUpdate: {
            userID: self.Userid,
            employeeID: self.EmployeeID,
            memberID: 0,
            username: self.UserID,
            firstName: "string",
            lastName: "string",
            email: "string",
            password: self.Password,
            userType: "string",
            roleID: 0,
            mobile: "string",
            employeeName: "string",
            positionID: 0,
            companyID: 0,
            createBy: "string",
            createDate: "2020-12-01T12:00:30.868Z",
            activeFlag: true,
            EmployeePermission: [
              {
                PermissionID: 1
              }
            ],
            lockStatus: self.lockStatus
          },
          createBy: "string",
          createDate: "2020-12-01T12:00:30.868Z",
          activeFlag: true
        };
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Company/UpdateCompany`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = self.$t('updatesuccess');
              self.flagAlertSend = true;
              
              self.flagLoadingSend = false;
              self.$router.push("/companymanagement");
            } else {
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t('companycodeemailuseridalreadyregistered');
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
            }
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    }
  },
  watch: {
    CompanyName: function(val) {
      if (val != "" || val != null) {
        this.v_CompanyName = false;
      } else {
        this.v_CompanyName = true;
      }
    },
    CompanyCode: function(val) {
      if (val != "" || val != null) {
        this.v_CompanyCode = false;
      } else {
        this.v_CompanyCode = true;
      }
    },
    Email: function(val) {
      if (val != "" || val != null) {
        this.v_Email = false;
      } else {
        this.v_Email = true;
      }
    },
    TelephoneNumber: function(val) {
      if (val != "" || val != null) {
        this.v_Phone = false;
      } else {
        this.v_Phone = true;
      }
    },
    Country: function(val) {
      if (val != "" || val != null) {
        this.v_Country = false;
      } else {
        this.v_Country = true;
      }
    },
    City: function(val) {
      if (val != "" || val != null) {
        this.v_City = false;
      } else {
        this.v_City = true;
      }
    },
    Address: function(val) {
      if (val != "" || val != null) {
        this.v_Address = false;
      } else {
        this.v_Address = true;
      }
    },
    UserID: function(val) {
      if (val != "" || val != null) {
        this.v_UserID = false;
      } else {
        this.v_UserID = true;
      }
    },
    Password: function(val) {
      if (val != "" || val != null) {
        this.v_Password = false;
      } else {
        this.v_Password = true;
      }
    }
  },
  mounted() {
    //console.log(this.$route.params.temp);
    this.FillDataEditCompany(this.$route.params.temp);
    // this.UserID = this.GenerateUserID();
    // this.Password = this.GeneratePassword();
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.flex {
  margin-left: 2rem;
  margin-right: 0rem;
}
.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.circular--portrait img {
  width: 100%;
  height: auto;
}
.input-border {
  border: 1px solid #ebebf2;
  height: 35px;
  padding: 4px;
}
.header-title {
  font-size: 13px;
  font-weight: 300;
}
::-webkit-input-placeholder {
  color: #444444;
  opacity: 20%;
  font-size: 14px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.lock_button_container{
  display: flex; 
  align-items: center;
  margin-top: 1rem;
}
.lock_button{ 
  cursor: pointer; 
}
.lock_button:hover {
  scale: 110%;
  transition: 0.1s
}
.lock_button:active {
  opacity: 0.8; 
}
.headerTable{
  color: #126496;
}
</style>