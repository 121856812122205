<template>
  <div>
    <v-card>
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{ $t('report5customerreport') }}
        <span class="ml-5 text-caption" style="color: #126496"
            >{{ CustomerReportListCount }} {{ $t('totalrecords') }}</span
          >
      
      </div>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                dense
                class="mt-5"
                v-model="search"
                outlined
                :placeholder="$t('search')"
                placeholder-color="#ACACAC"
                prepend-inner-icon="mdi-magnify"
                
                color="#126496"
                @keypress="searchenter"
              />
            </v-col>            
             <v-col cols="12" sm="6" md="2" >
              <label class="for--text">{{ $t('startdate') }}</label>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    v-model="StartDate"
                    @change="v => {StartDate = v;CheckValueStartDate(StartDate)}"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="background-color:rgb(25, 118, 210);width:39px;height:40px;cursor:pointer;margin-top:-8px;margin-right: -11px;border-radius:3px"
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left:10px;margin-top:10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @change="CheckValueStartDate(date)"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <label class="for--text">{{ $t('enddate') }}</label>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    v-model="EndDate"
                    @change="v => {EndDate = v;CheckValueEndDate(EndDate)}"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <div
                        v-on="on"
                        style="background-color:rgb(25, 118, 210);width:39px;height:40px;cursor:pointer;margin-top:-8px;margin-right: -11px;border-radius:3px"
                      >
                        <img
                          src="@/assets/Mask Group 27.png"
                          width="20"
                          style="margin-left:10px;margin-top:10px"
                        />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date1"
                  
                  :allowed-dates="allowedDatesEnd"
                  
                  
                  @change="CheckValueEndDate(date1)"
                  no-title
                  
                  @input="endDateDis(),(menu2 = false)"
                
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="2" lg="1">
            <v-btn width="100%" color="#1976d2" class="white--text mt-5 applyBtn" router @click="SearchCustomerReport()">Apply</v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="1">
            <v-btn color="#1976d2" class="white--text mt-5" router @click="ClearSearchData()">Clear</v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2">
            <v-btn width="100%" color="#1976d2" class="white--text mt-5 moveRight" router @click="ExportCSV()">Export CSV</v-btn>
            </v-col> -->

            <v-col cols="12"  sm="6" md="2" lg="1">
              <v-btn width="100%" color="#1976d2 " class="white--text mt-5 applyBtn" router @click="SearchCustomerReport()">{{ $t('apply') }}</v-btn>
               <!-- <v-btn color="#1976d2 " class="white--text mt-5 clearBtn" router @click="ClearSearchData()">Clear</v-btn> -->
            </v-col>
            <v-col cols="12"  sm="6" md="1" lg="1" >
              <v-btn width="100%" color="#1976d2 " class="white--text mt-5 " router @click="ClearSearchData()">{{ $t('clear') }}</v-btn>
            </v-col>
           <v-spacer></v-spacer>
            <v-col cols="12"  sm="4" md="2"  lg="1" >
              <v-btn
                width="100%"
                color="#1976d2 "
                class="white--text mt-5 moveRight"
               
                router
                @click="ExportCSV()"
              >{{ $t('exportCSV') }}</v-btn>
            </v-col>
          </v-row>

            <v-data-table
              class="mt-5"
              :headers="headers"
              :items="CustomerReportList"
              hide-default-footer
              style="color: #444444"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              single-line
              hide-details
              :mobile-breakpoint="0"
              :no-data-text="$t('nodataavailable')"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ no_run + (index + 1) }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ item.currentPackage }}</td>
                  <td>{{ item.currentUsage }}</td>
                  <td>{{ item.contactPerson }}</td>
                  <td>{{ item.companyEmail }}</td>
                  <td>{{ item.companyTelephone }}</td>
                  <td>{{ item.pricingPlan }}</td>
                  <td>{{ item.startDate }}</td>
                  <td>{{ item.expireDate }}</td>
                  <td>{{ item.sale }}</td>
                </tr>
              </template>
            </v-data-table>

          <br>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="1">
              <v-autocomplete
                dense
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="ChangePerPage(itemsPerPage)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="9" sm="9" md="11">
              <v-pagination
                v-model="page"
                :total-visible="7"
                :length="pageCount"
                @input="ChangePage(page)"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";
export default {
  components: {
    Alert,
    Loading,
  },
  props: {},
  data() {
    return {
      prevSearch: "",
      isSearch: false,
      prevStartDate: "",
      isStartDate: false,
      prevEndDate: "",
      isEndDate: false,
      no_run: null,
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      url: enurl.apiUrl,
      CustomerReportListCount: 0,
      CustomerReportList:[],
      flagLoadingSend: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      items: [10, 15, 20],
      menu1: false,
      menu2: false,
      //maxDate: null,
      StartDate: "",
      EndDate: "",
      date: new Date().toISOString().substr(0, 10),
      date1:null,
      dateFormatted: moment(new Date().toISOString().substr(0, 10))
        // .add(543, "years")
        // .lang("th")
        .format("DD/MM/YYYY"),
      dateFormatted1: moment(new Date().toISOString().substr(0, 10))
        // .add(543, "years")
        // .lang("th")
        .format("DD/MM/YYYY")
    };
  },
  computed: {
    // allowedDates() {
    //   if (this.date1) {
    //     return (date) => date <= this.date1;
    //   }
    //   return null;
    // },
    allowedMonths() {
      if (this.date1) {
        return (month) => month >= this.date1.getMonth();
      }
      return null;
    },
    allowedYears() {
      if (this.date1) {
        return (year) => year >= this.date1.getFullYear();
      }
      return null;
    },
    headers() {
      return [
      {
          text: this.$t('no'),
          align: "left",
          sortable: false
        },
        {
          text: this.$t('companyname'),
          value: "companyname",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('currentpackage'),
          value: "currentPackage",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('currentusage'),
          value: "currentUsage",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('contactperson'),
          value: "contactPerson",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('companyemail'),
          value: "companyEmail",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('companytelephone'),
          value: "companyTelephone",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('pricingplan'),
          value: "pricingPlan",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('startdate'),
          value: "sDate",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('expiredate'),
          value: "eDate",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('sale'),
          value: "sale",
          align: "left",
          sortable: false
        },
      ];
    }
  },
  watch: {
    search(oldVal, newVal){
      if(oldVal != newVal){
        this.isSearch = false
      }
    },
    startDate(oldVal, newVal){
      if(oldVal != newVal){
        this.isStartDate = false
      }
    },
    endDate(oldVal, newVal){
      if(oldVal != newVal){
        this.isEndDate = false
      }
    },
    // search(){
    //   this.SearchCustomerReport();
    // },
    date() {
      this.dateFormatted = this.DateFormat(this.date);
      // if(this.StartDate > this.EndDate){
        this.EndDate = "";
       // this.maxDate = null;
      //}
    },
    date1() {
      this.dateFormatted = this.DateFormat(this.date1);
    },
    
  },
  mounted() {
    this.GetCustomerReport();
  },
  methods: {
    endDateDis() {
      if (this.date == "") {
        this.date = this.date1;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.date;
    },
    // allowedMonths(val) {
    //     return val >= this.date.getMonth();
    // },
    // allowedYears(val) {
    //     return val >= this.date.getFullYear();
    // },


    searchenter: function(e) 
    {
      if(e.keyCode === 13)
      {
        this.SearchCustomerReport();
      }
    },
    
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },

    ClearSearchData(){
      this.search = "";
      this.StartDate = "";
      this.EndDate = "";
      this.date = "",
      this.date1 ="",
      window.location.reload();


    },

    DateFormat(value) {
      return moment(value)
        // .add(543, "years")
        // .lang("th")
        .format("DD/MM/YYYY");
    },
    

    CheckValueStartDate(value) {
      this.StartDate = this.DateFormat(value);
      // // if (value == "") {
      // //   this.date = "";
      // // } else {
      // //   if(this.EndDate == ""){
      // //     this.StartDate = this.DateFormat(value);
      // //   }else{
      // //     // const ArrEdate = this.EndDate.split("/");
      // //     // const Edate = (parseInt(ArrEdate[2]) - 543) + "-" + ArrEdate[1] + "-" + ArrEdate[0];
      // //     // console.log(Edate);
      // //     if(new Date(this.EndDate) >= new Date(value)){
      // //       this.StartDate = this.DateFormat(value);
      // //     }else{
      // //       this.typeAlertSend = "warning"; //success warning error
      // //       this.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น";
      // //       this.flagAlertSend = true;
      // //       this.date = "";
      // //     }
      // //   }
      // // }
      
    },
    CheckValueEndDate(value) {
      this.EndDate = this.DateFormat(value);
      // if (value == "") {
      //   this.date1 = "";
      // } else {
      //   if(this.StartDate == ""){
      //     this.EndDate = this.DateFormat(value);
      //   }else{
      //     if(new Date(this.StartDate) <= new Date(value)){
      //       this.EndDate = this.DateFormat(value);
      //     }else{
      // //       this.typeAlertSend = "warning"; //success warning error
      // //       this.mesAlertSend = "กรุณาเลือกวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น";
      // //       this.flagAlertSend = true;
      // //       // alert("Please select an end date greater than the start date.");
      //        this.date1 = "";
      //     }
      //   }
      //}

      
    },

    GetCustomerReport(){
      let self = this;
      if(this.search=="")
      {
      self.flagLoadingSend = true;
      }
      let tempdata = {
        Page: self.page,
        PerPage: self.itemsPerPage,
        StartDate: self.StartDate,
        expireDate: self.EndDate,
      };
      axios
      .post(`${self.url}Report/SearchCustomerReport`, tempdata)
        .then(function (response) { 
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CustomerReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.CustomerReportListCount = response.data.data.result.dataCount;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.CustomerReportList = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t('nodatafound');
            self.flagAlertSend = true;
          }
      })
      .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    ChangePerPage: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        expireDate: self.EndDate,
        Search: self.search,
        Page: 1,
        PerPage: value
      };
      axios
        .post(`${self.url}Report/SearchCustomerReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.page = 1; 
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CustomerReportList = response.data.data.result.listData;  
            self.flagLoadingSend = false;
           }
          else {
            self.flagLoadingSend = false;
            self.CustomerReportList = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t('nodatafound');
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    ChangePage: function(value) {
      let self = this;
      self.flagLoadingSend = true;
      let temp_search = self.prevSearch
      if(self.isSearch){
        temp_search = self.search
      }
      let temp_startDate = self.prevStartDate
      if(self.isStartDate){
        temp_startDate = self.StartDate
      }
      let temp_endDate = self.prevEndDate
      if(self.isEndDate){
        temp_endDate = self.EndDate
      }
      let tempdata = {
       StartDate: temp_startDate,
       expireDate: temp_endDate,
        Search: temp_search,
        Page: value,
        PerPage: self.itemsPerPage
      };
      axios
        .post(`${self.url}Report/SearchCustomerReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CustomerReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.CustomerReportList = [];
            self.pageCount = 0;
            self.page = 1;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t('nodatafound');
            self.flagAlertSend = true;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },

    SearchCustomerReport: function() {
      let self = this;
      if((self.StartDate == "" && self.EndDate == "") || (self.StartDate != "" && self.EndDate != "")){
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        expireDate: self.EndDate,
        Page: 1,
        PerPage: self.itemsPerPage,
        Search: self.search
      };
      axios
        .post(`${self.url}Report/SearchCustomerReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            self.page = 1;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.CustomerReportList = response.data.data.result.listData;
            self.pageCount = response.data.data.result.count;
            self.CustomerReportListCount = response.data.data.result.dataCount;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.ListData = [];
            self.pageCount = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t('nodatafound');
            self.flagAlertSend = true;
          }
          self.prevSearch = self.search;
          self.prevStartDate = self.StartDate;
          self.prevEndDate = self.EndDate;
          this.isSearch = true;
          this.isStartDate = true;
          this.isEndDate = true;
          

        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
      }
      else if (self.StartDate == ""){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t('pleaseselectastartdate');
        self.flagAlertSend = true;
      }
      else if (self.EndDate == ""){
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t('pleaseselectanenddate');
        self.flagAlertSend = true;
      }
    },
    ExportCSV: function() {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        StartDate: self.StartDate,
        expireDate: self.EndDate,
        Page: 1,
        PerPage: self.itemsPerPage,
        Search: self.search
      };
      axios
        .post(`${self.url}Report/ExportCsvCustomerReport`, tempdata)
        .then(function(response) {
          if (response.data.status == 0) {
            var tempdata = response.data.data.result;
            console.log(tempdata);
            const blobPDF = self.base64ToBlob(tempdata, "text/csv;charset=utf-8,BOM");
            const linkSource = URL.createObjectURL(blobPDF);
            var link = document.createElement("a");
            var date = new Date();
            link.download = "Report5_" + date.toLocaleString("th-TH") + ".csv";
            link.href = linkSource;
            link.click();

            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
          }
        })
        .catch(error => {
          console.log(error.response);
          self.flagLoadingSend = false;
        });
    },
    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
  }
};
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .moveRight{
    float:right;
  }
}

/* @media (min-width: 960px){
* >>> .repo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}
} */

* >>> .clearBtn {
margin-left: 5px !important;
} 


* >>> .v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
</style>

<style lang="scss">

.__pagination {
  .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #0000001a;
    width: auto !important;
    padding-left: 10px;
    padding-right: 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>
